import React, { useContext , useEffect} from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ShopContext } from 'context/shop-context';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function DiscountTable (props) {
  const { className, ...rest } = props;
  const {discount, deleteDiscount} = useContext(ShopContext);

  const classes = useStyles();
  const toastMessage = localStorage.getItem('toastMessage');

  useEffect(() => {
    toast.success(toastMessage)
    localStorage.removeItem('toastMessage');
  }, []);
  let counter = 1;

// var d22 = new Date(); //"now"
// var d2 = new Date("2023-06-08");  // some date
// var diff = Math.abs(d22-d2);  
// let day = parseInt(diff/ (1000*60*60*24));
// console.log(day);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <Divider />
     
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>التسلسل</TableCell>
                  <TableCell align='right'> الرمز</TableCell>
                  <TableCell  align='right'> نسبة الخصم</TableCell>
                  <TableCell  align='right'> حالة الخصم</TableCell>
                  <TableCell align='right'>تاريخ البداية</TableCell>
                  <TableCell align='right'>تاريخ النهاية</TableCell>
                  <TableCell align='right'> تعديل</TableCell>
                  <TableCell align='right'>حذف</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {discount.map(order => (
                  <TableRow
                    hover
                    key={order.id}
                  >
                    <TableCell align='right'>{ counter++} </TableCell>
                    <TableCell align='right'>{order.promo_code}</TableCell>
                    <TableCell align='right'><span>%</span>{ parseInt(order.discount)} </TableCell>
                    {order.is_active == 1 ? 
                     <TableCell align='right'>مفعل</TableCell>
                    :
                    <TableCell align='right'>غير مفعل</TableCell>
                    }
                    <TableCell align='right'>{moment(order.created_dateTime).format('DD-MM-YYYY')}</TableCell>
                    <TableCell align='right'>{moment(order.expiration_dateTime).format('DD-MM-YYYY')}</TableCell>
                    <TableCell align='right'>
                    <Link to={`/edit-discount/${order.id}`} className='btn btn-primary btn-sm' ><FaEdit size="20px" /></Link>
                    </TableCell>
              
                    <TableCell align='right'>
                    <button className='btn btn-danger btn-sm' onClick={(e)=> deleteDiscount(e, order.id)} ><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>
                  </TableRow>
                 ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
    
      </CardActions>
    </Card>
  );
}

export default DiscountTable;
