import React, { Fragment } from 'react';
import { Map } from 'views/MapList/components';
import { PDFDownloadLink, PDFViewer , StyleSheet} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';


// Create Document Component
const  Invoice = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const {id} = useParams();
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get(`${BASE_URL}/requests/${id}`).then((res)=>{
      setData(res.data);
    }).catch((err)=>{
      console.log(err);
    })
  }, []);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '100%',
      height: '100%',
      // height:800,
    },
})

  return(
    <>
      <PDFDownloadLink document={<Map items={data} />} fileName="dam-invoice.pdf">
        {({ loading }) => loading ? 'جاري...' : 'تحميل الفاتورة'}
      </PDFDownloadLink>
    <PDFViewer style={styles.page}>
      <Map items={data} />
    </PDFViewer>
   </>
  );
}

export default Invoice; 
