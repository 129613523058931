import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditQuestion = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const EDIT_QUESTION_URL = `${BASE_URL}/questions/${id}`;
    
    var accessTokenObj = (localStorage.getItem("token"));
    const passedToken = accessTokenObj;
  
    const [data, setData] = useState({
      ar_question: "",
      en_question: "",
      ar_answer: "",
      en_answer: "",
      is_active: null,
    });

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadList();
      }, []);
      const loadList = async () => {
        const res = await axios.get(EDIT_QUESTION_URL,{ headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      });
        setData(res.data)
      };

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();

        formData.append("ar_question", data.ar_question);
        formData.append("en_question", data.en_question);
        formData.append("ar_answer", data.ar_answer);
        formData.append("en_answer", data.en_answer);
        formData.append("is_active", data.is_active);
        await fetch(EDIT_QUESTION_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}` 
          }
        })
        .then((res)=>{
          console.log(res);
          if(!res.status){
            toast.error('خطأ في الاتصال بالخادم')
            setLoader(false);
          }

          else if(res.status == 500){
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('تأكد من الاتصال بالانترنت و المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 201){
            setLoader(false);
            localStorage.setItem('toastMessage', 'تم تعديل البيانات');
            window.location.replace('/quesions');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          } 
          loadList();
        })
        .catch(()=>{
          setLoader(false);
          toast.error('حدث خطا الرجاء المحاولة مرة أخرى');
        });
      };
      
      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>

      

      <form onSubmit={handleSubmission}>
        <CardHeader
          // subheader="The information can be edited"
          title="تعديل الاسئلة الشائعة"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="السؤال باللغة العربية"
                label="السؤال باللغة العربية"
                margin="dense"
                id="ar_question"
                type="text" 
                name="ar_question"
                onChange={(e) => handle(e)}
                value={data.ar_question}
                required
                variant="outlined"
              />
            </Grid>
              
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="الإجابة باللغة العربية"
                label="الإجابة باللغة العربية"
                margin="dense"
                id="ar_answer"
                type="text" 
                name="ar_answer"
                onChange={(e) => handle(e)}
                value={data.ar_answer}
                required
                variant="outlined"
              />
            </Grid>


            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                helperText="السؤال باللغة الإنجليزية"
                label="السؤال باللغة الإنجليزية"
                margin="dense"
                id="en_question"
                type="text"
                name="en_question"
                onChange={(e) => handle(e)} 
                value={data.en_question}
                required
                variant="outlined"
                
              />
              </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                helperText="الإجابة باللغة الإنجليزية"
                label="الإجابة باللغة الإنجليزية"
                margin="dense"
                id="en_answer"
                type="text"
                name="en_answer"
                onChange={(e) => handle(e)} 
                value={data.en_answer}
                required
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="حالة الإعلان"
                helperText="أختر الصنف بالعربي"
                margin="dense"
                id="is_active"
                name="is_active"
                onChange={(e)=> handle(e)} 
                value={data.is_active}
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
             <option value={1}>مفعل </option>
             <option value={0}>غير مفعل </option>
              </TextField>

            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>


<button className='btn btn-warning text-white px-3  m-4'  >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>تعديل البيانات</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditQuestion.propTypes = {
  className: PropTypes.string
};

export default EditQuestion;
