import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  CategoryList as CategoryListView,
  UserList as UserListView,
  Icons as IconsView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  AddCategory as AddCategoryView,
  EditCategory as EditCategoryView,
  CustomersList as CustomersListView,
  DriversList as DriversListView,
  CompaniesList as CompaniesListView,
  AgentsList as AgentsListView,
  OrdersList as OrdersListView,
  ChatsList as ChatsListView,
  MapList as MapListView,
  SubCategoryList as SubCategoryListView,
  ServiceList as ServiceListView,
  FrequentlyQuestionsList as FrequentlyQuestionsListView,
  CitiesList as CitiesListView,
  PromotionsList as PromotionsListView,
  DriverSettings as DriverSettingsView,
  AgentSettings as AgentSettingsView,
  FrontImage as FrontImageView,
  SideImage as SideImageView,
  AddCity as AddCityView,
  EditCity as EditCityView,
  AddPromotion as AddPromotionView,
  EditPromotion as EditPromotionView,
  AddQuestion as AddQuestionView,
  EditQuestion as EditQuestionView,
  WelcomesList as WelcomesListView,
  PrivacyPoliciesList as PrivacyPoliciesListView,
  AddWelcome as AddWelcomeView,
  EditWelcome as EditWelcomeView,
  AddPolicy as AddPolicyView,
  EditPolicy as EditPolicyView,
  AddSubCategory as AddSubCategoryView,
  EditSubCategory as EditSubCategoryView,
  AddService as AddServiceView,
  EditService as EditServiceView,
  OTPMessageList as OTPMessageListView,
  DiscountList as DiscountListView,
  AddDiscount as AddDiscountView,
  EditDiscount as EditDiscountView,
  AddUser as AddUserView,
  EditUser as EditUserView,
  ProjectsList as ProjectsListView,
  VehicleRequestList as VehicleRequestListView,
  ContractImage as ContractImageView,
  VehicleModelList as VehicleModelListView,
  EditVehicleModel as EditVehicleModelView,
  AddVehicleModel as AddVehicleModelView,
  HeavyMachineList as HeavyMachineListView,
  HeavyMachineImage as HeavyMachineImageView,
  HeavyMachineSizeList as HeavyMachineSizeListView,
  AddHeavyMachineSize as AddHeavyMachineSizeView,
  EditHeavyMachineSize as EditHeavyMachineSizeView,
  ArchivedUserList as ArchivedUserListView,
  AgentDriverList as AgentDriverListView,
  AgentOrderList as AgentOrderListView,
  DriverOrderList as DriverOrderListView,
  DriverServiceList as DriverServiceListView,
  TruckQuestionsList as TruckQuestionsListView,
  AddTruckQuestion as AddTruckQuestionView,
  EditTruckQuestion as EditTruckQuestionView,
  CancelReasonsList as CancelReasonsListView,
  AddCancelReason as AddCancelReasonView,
  EditCancelReason as EditCancelReasonView,
  ErrorLogList as ErrorLogListView,
  AppSettingList as AppSettingListView,
  AddAppSetting as AddAppSettingView,
  EditAppSetting as EditAppSettingView,
  Invoice as InvoiceView,
  RolesList as RolesListView,
  AddRole as AddRoleView,
  EditRole as EditRoleView
  
} from './views';
import { Chats } from 'views/ChatsList/components';


const Routes = () => {

  const loggedIn = localStorage.getItem("isLoggedIn");  
  console.log(loggedIn, "login");
  return (
    
    <Switch>
            <RouteWithLayout
        component={InvoiceView}
        exact
        layout={MinimalLayout}
        path="/invoice/:id"
      />

          <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
 {loggedIn?
      <>
      <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
       <Switch path="/chat/:chatId" component={Chats} />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
       <RouteWithLayout
        component={CategoryListView}
        exact
        layout={MainLayout}
        path="/category"
      />
      <RouteWithLayout
        component={SubCategoryListView}
        exact
        layout={MainLayout}
        path="/sub-category"
      />
      <RouteWithLayout
        component={ServiceListView}
        exact
        layout={MainLayout}
        path="/services"
      />
      <RouteWithLayout
        component={CustomersListView}
        exact
        layout={MainLayout}
        path="/customers"
      />
      <RouteWithLayout
        component={DriversListView}
        exact
        layout={MainLayout}
        path="/drivers"
      />
      <RouteWithLayout
        component={CompaniesListView}
        exact
        layout={MainLayout}
        path="/companies"
      />
          <RouteWithLayout
        component={AgentsListView}
        exact
        layout={MainLayout}
        path="/agents"
      />
           <RouteWithLayout
        component={OrdersListView}
        exact
        layout={MainLayout}
        path="/orders"
      />
           <RouteWithLayout
        component={ChatsListView}
        exact
        layout={MainLayout}
        path="/chats"
      />
      
      <RouteWithLayout
        component={MapListView}
        exact
        layout={MainLayout}
        path="/map"
      />
      
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
        <RouteWithLayout
        component={CitiesListView}
        exact
        layout={MainLayout}
        path="/cities"
      />
       <RouteWithLayout
        component={PromotionsListView}
        exact
        layout={MainLayout}
        path="/promotions"
      />
          <RouteWithLayout
        component={FrequentlyQuestionsListView}
        exact
        layout={MainLayout}
        path="/quesions"
      />
       <RouteWithLayout
        component={WelcomesListView}
        exact
        layout={MainLayout}
        path="/welcomes"
      />
         <RouteWithLayout
        component={OTPMessageListView}
        exact
        layout={MainLayout}
        path="/opt-messages"
      />
       <RouteWithLayout
        component={PrivacyPoliciesListView}
        exact
        layout={MainLayout}
        path="/policies"
      />
         <RouteWithLayout
        component={DiscountListView}
        exact
        layout={MainLayout}
        path="/discount"
      />
        <RouteWithLayout
        component={ProjectsListView}
        exact
        layout={MainLayout}
        path="/projects"
      />
      <RouteWithLayout
        component={VehicleRequestListView}
        exact
        layout={MainLayout}
        path="/contracts"
      />
           <RouteWithLayout
        component={VehicleModelListView}
        exact
        layout={MainLayout}
        path="/models"
      />
         <RouteWithLayout
        component={HeavyMachineListView}
        exact
        layout={MainLayout}
        path="/heavy-machine"
      />
         <RouteWithLayout
        component={HeavyMachineSizeListView}
        exact
        layout={MainLayout}
        path="/heavy-machine-size"
      />
       <RouteWithLayout
        component={TruckQuestionsListView}
        exact
        layout={MainLayout}
        path="/truck-question"
      />

          <RouteWithLayout
        component={CancelReasonsListView}
        exact
        layout={MainLayout}
        path="/cancel-reason"
      />
            <RouteWithLayout
        component={ErrorLogListView}
        exact
        layout={MainLayout}
        path="/error-log"
      />
      
        <RouteWithLayout
        component={AppSettingListView}
        exact
        layout={MainLayout}
        path="/app-setting"
      />

        <RouteWithLayout
        component={ArchivedUserListView}
        exact
        layout={MainLayout}
        path="/archived-user"
      />
         <RouteWithLayout
        component={RolesListView}
        exact
        layout={MainLayout}
        path="/roles"
      />
             <RouteWithLayout
        component={AddCityView}
        exact
        layout={MainLayout}
        path="/add-city"
      />
      <RouteWithLayout
        component={AddPromotionView}
        exact
        layout={MainLayout}
        path="/add-promotion"
      />
      <RouteWithLayout
        component={AddQuestionView}
        exact
        layout={MainLayout}
        path="/add-question"
      />
         <RouteWithLayout
        component={AddWelcomeView}
        exact
        layout={MainLayout}
        path="/add-welcome"
      />
          <RouteWithLayout
        component={AddPolicyView}
        exact
        layout={MainLayout}
        path="/add-policy"
      />
      <RouteWithLayout
        component={AddSubCategoryView}
        exact
        layout={MainLayout}
        path="/add-sub-category"
      />
         <RouteWithLayout
        component={AddServiceView}
        exact
        layout={MainLayout}
        path="/add-service"
      />
        <RouteWithLayout
        component={AddDiscountView}
        exact
        layout={MainLayout}
        path="/add-discount"
      />
          <RouteWithLayout
        component={AddUserView}
        exact
        layout={MainLayout}
        path="/add-user"
      />
        <RouteWithLayout
        component={AddVehicleModelView}
        exact
        layout={MainLayout}
        path="/add-vehicle-model"
      />
         <RouteWithLayout
        component={AddHeavyMachineSizeView}
        exact
        layout={MainLayout}
        path="/add-heavy-size"
      />
         <RouteWithLayout
        component={AddTruckQuestionView}
        exact
        layout={MainLayout}
        path="/add-truck-question"
      />
           <RouteWithLayout
        component={AddRoleView}
        exact
        layout={MainLayout}
        path="/add-role"
      />
           <RouteWithLayout
        component={AddCancelReasonView}
        exact
        layout={MainLayout}
        path="/add-cancel-reason"
      />
      <RouteWithLayout
        component={AddAppSettingView}
        exact
        layout={MainLayout}
        path="/add-app-setting"
      />
                <RouteWithLayout
        component={EditCityView}
        exact
        layout={MainLayout}
        path="/edit-city/:id"
      />
       <RouteWithLayout
        component={EditPromotionView}
        exact
        layout={MainLayout}
        path="/edit-promotion/:id"
      />
          <RouteWithLayout
        component={EditQuestionView}
        exact
        layout={MainLayout}
        path="/edit-question/:id"
      />
           <RouteWithLayout
        component={EditWelcomeView}
        exact
        layout={MainLayout}
        path="/edit-welcome/:id"
      />
          <RouteWithLayout
        component={EditPolicyView}
        exact
        layout={MainLayout}
        path="/edit-policy/:id"
      />
           <RouteWithLayout
        component={EditSubCategoryView}
        exact
        layout={MainLayout}
        path="/edit-sub-category/:id"
      />
            <RouteWithLayout
        component={EditServiceView}
        exact
        layout={MainLayout}
        path="/edit-service/:id"
      />
             <RouteWithLayout
        component={EditDiscountView}
        exact
        layout={MainLayout}
        path="/edit-discount/:id"
      />
             <RouteWithLayout
        component={EditUserView}
        exact
        layout={MainLayout}
        path="/edit-user/:id"
      />
          <RouteWithLayout
        component={EditVehicleModelView}
        exact
        layout={MainLayout}
        path="/edit-vehicle-model/:id"
      />
            <RouteWithLayout
        component={EditHeavyMachineSizeView}
        exact
        layout={MainLayout}
        path="/edit-heavy-size/:id"
      />
            <RouteWithLayout
        component={EditTruckQuestionView}
        exact
        layout={MainLayout}
        path="/edit-truck-question/:id"
      />
           <RouteWithLayout
        component={EditCancelReasonView}
        exact
        layout={MainLayout}
        path="/edit-cancel-reason/:id"
      />
       <RouteWithLayout
        component={EditAppSettingView}
        exact
        layout={MainLayout}
        path="/edit-app-setting/:id"
      />
         <RouteWithLayout
        component={EditRoleView}
        exact
        layout={MainLayout}
        path="/edit-role/:id"
      />
      <RouteWithLayout
        component={DriverSettingsView}
        exact
        layout={MainLayout}
        path="/driver-settings/:id"
      />
        <RouteWithLayout
        component={AgentSettingsView}
        exact
        layout={MainLayout}
        path="/agent-settings/:id"
      />
        <RouteWithLayout
        component={AgentDriverListView}
        exact
        layout={MainLayout}
        path="/agent-driver-list/:id"
      />
           <RouteWithLayout
        component={AgentOrderListView}
        exact
        layout={MainLayout}
        path="/agent-order-list/:id"
      />
      
      <RouteWithLayout
        component={DriverOrderListView}
        exact
        layout={MainLayout}
        path="/driver-order-list/:id"
      />

       <RouteWithLayout
        component={DriverServiceListView}
        exact
        layout={MainLayout}
        path="/driver-service-list/:id"
      />
      
         <RouteWithLayout
        component={FrontImageView}
        exact
        layout={MainLayout}
        path="/front-image/:id"
      />
           <RouteWithLayout
        component={SideImageView}
        exact
        layout={MainLayout}
        path="/side-image/:id"
      />

      <RouteWithLayout
        component={ContractImageView}
        exact
        layout={MainLayout}
        path="/contract-image/:id"
      />
      <RouteWithLayout
        component={HeavyMachineImageView}
        exact
        layout={MainLayout}
        path="/heavy-image/:id"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
        <RouteWithLayout
        component={AddCategoryView}
        exact
        layout={MainLayout}
        path="/add-category"
      />
        <RouteWithLayout
        component={EditCategoryView}
        exact
        layout={MainLayout}
        path="/edit-category/:id"
      />
      <Redirect to="/not-found" />
      </Switch>
      </>
      : <Redirect exact to="/sign-in" />}
      
     
    </Switch>
   
  );
};

export default Routes;
