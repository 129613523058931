import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { CgOrganisation } from "react-icons/cg";
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'

  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {


    
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

function TotalUsers (props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const {companyLen} = useContext(ShopContext);
  
 
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
             الشركات
            </Typography>
            <Typography variant="h3">{companyLen}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
            <CgOrganisation size={28}/>
            </Avatar>
          </Grid>
        </Grid>
  
      </CardContent>
    </Card>
  );
}

export default TotalUsers;
