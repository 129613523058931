import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import {  useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';
import axios from 'axios';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditSubCategory = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const {categories} = useContext(ShopContext);

  const {id} = useParams();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const SUBCATEGORY_URL = `${BASE_URL}/subCategories/${id}`; 
  const [loader, setLoader] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  // const history = useHistory();
  useEffect(() => {
    loadList();
  }, []);
  const loadList = async () => {
    const res = await axios.get(SUBCATEGORY_URL,{ headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  });
    setData(res.data)
  };


  const [data, setData] = useState({
      ar_name : "",
      en_name : "",
      is_truck: 0,
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("photo", selectedFile);
        formData.append("ar_name", data.ar_name);
        formData.append("en_name", data.en_name);
        formData.append("cat_id", data.cat_id);
        formData.append("is_truck", data.is_truck);
        await fetch (SUBCATEGORY_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
              localStorage.setItem('toastMessage', 'تم تعديل بيانات القسم ');
              setLoader(false);
                window.location.replace('/sub-category');
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="تعديل بيانات القسم الفرعي"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="اسم القسم باللغة العربية"
                margin="dense"
                name="ar_name"
                id="ar_name"
                onChange={(e)=> handle(e)} 
                value={data.ar_name}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="اسم القسم باللغة الإنجليزية"
                margin="dense"
                name="en_name"
                id="en_name"
                onChange={(e)=> handle(e)} 
                value={data.en_name}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // label="أختر الصنف"
                helperText="الصنف بالعربي"
                margin="dense"
                id="cat_id"
                name="cat_id"
                onChange={(e)=> handle(e)} 
                value={data.cat_id}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                   <option value={null}>حدد القسم الرئيسي</option>
                {categories.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>
              {/* {showArError && <span className='text-danger'>هذا الحقل اجباري</span>} */}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // label="أختر الصنف"
                helperText="الصنف بالعربي"
                margin="dense"
                id="is_truck"
                name="is_truck"
                onChange={(e)=> handle(e)} 
                value={data.is_truck}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                   <option value={null}> حدد النوع </option>
                   <option value={1}>  سطحه </option>
                   <option value={0}> أخرى </option>
              </TextField>
              {/* {showArError && <span className='text-danger'>هذا الحقل اجباري</span>} */}
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
               {/* <label for="inputImage">أضف صورة الإعلان </label> */}
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
          
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-warning text-white px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>تعديل البيانات </>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditSubCategory.propTypes = {
  className: PropTypes.string
};

export default EditSubCategory;
