import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider
} from '@material-ui/core';
import { options } from './chart';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(() => ({

  
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Orders = props => {
  const { className, ...rest } = props;
  const {allCompletedOrders, allCanceledOrders, allInProgressOrders} = useContext(ShopContext);
  const data = {
    label: 'الطلبات',
    datasets: [
      {
        label: 'الطلبات المنفذة',
        data: [allCompletedOrders],
        backgroundColor: palette.success.main,
        fill: false,
      },
      {
        label: 'الطلبات الملغاة',
        data: [allCanceledOrders],
        backgroundColor: palette.secondary.main,
        fill: false,
      },
      {
        label: 'الطلبات الحالية',
        data: [allInProgressOrders],
        backgroundColor: palette.primary.main,
        fill: false,
      }
    ]
  };

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
    
        title="الطلبات"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar 
            data={data}
            options={options} 
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        
      </CardActions>
    </Card>
  );
};

Orders.propTypes = {
  className: PropTypes.string
};

export default Orders;
