import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Loader from "react-js-loader"; 
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const BASE_URL = process.env.REACT_APP_API_URL;
  const PASSWORD_CHANAGE_URL = `${BASE_URL}/changePassword`;
 
  const [pwd, setPwd] = useState('');
  const [pwdConf, setPwdConf] = useState('');
  const [loader, setLoader] = useState(false);


  const handleSubmit = async (e) =>{
    e.preventDefault();

    setLoader(true);
    try{
      const res = await axios.post(PASSWORD_CHANAGE_URL,
        JSON.stringify({password: pwd, password_confirmation: pwdConf }),
        {
          headers:{'Content-Type':'application/json', 
          'Authorization': ` Bearer ${passedToken}`},
        }
         );
         console.log(res.data);

      setPwd('')
       window.location.replace('/sign-in')
    } catch(err){
      console.log(err.response.status)
     if(!err.response.status){
      console.log(err)
      toast.error(' حدث خطا الرجاء المحاولة مرة أخرى');
      setLoader(false);
     }
     else if(err.response.status === 400 ){
      toast.error(' حدث خطا الرجاء المحاولة مرة أخرى');
     }
 
     else if(err.response.status === 422){
      toast.error('كلمة المرور غير متطابقة');
      setLoader(false);
     }
     else{
      toast.error(' حدث خطا الرجاء المحاولة مرة أخرى');

      setLoader(false);
     }
     err.current.focus();
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form onSubmit={handleSubmit}>
        <CardHeader
          subheader="إعادة تعيين كلمة المرور"
          title="كلمة المرور"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="كلمة المرور"
            name="password"
            type="password"
            onChange={(e)=> setPwd(e.target.value)}
            value={pwd}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label="تاكيد كلمة المرور"
            name="confirm"
            onChange={(e)=> setPwdConf(e.target.value)}
            style={{ marginTop: '1rem' }}
            type="password"
            value={pwdConf}
            variant="outlined"
            required
          />
        </CardContent>
        <Divider />
        <CardActions>

      <button className='btn btn-warning px-3  m-4 text-white' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إعادة التعيين </>
        }
      </button>

        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
