import { Grid } from "@material-ui/core";
import axios from "axios"; 
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';


const EditRole = ()=>{

    
    const [role,setRole] = useState('');
    const [pages,setPages] = useState('');
    const [operations, setOperations] = useState([]);
    const [checkboxes, setCheckboxes] = useState([]);

    const BASE_URL = process.env.REACT_APP_API_URL;

    var accessTokenObj = (localStorage.getItem("token"));
    const passedToken = accessTokenObj;

    
console.log(setCheckboxes);
    const handleChange = (e) => 
    {
        console.log(e.target.checked);
        if (e.target.checked) 
        {
            if(!checkboxes.includes(parseInt(e.target.value)))
            {
              
                checkboxes.push(parseInt(e.target.value));
                console.log(checkboxes);
            }
            else
            {
                checkboxes.filter((cur) => cur !== e.target.value);
                console.log(checkboxes);
            }
        } 
        else
        {
          
            let value = parseInt(e.target.value);
            var index = checkboxes.indexOf(value);
            if (index !== -1) 
            {
                checkboxes.splice(index, 1);
              }
            console.log(checkboxes);  
        }

        setOperations(checkboxes);

    }

    const {id} = useParams();

    useEffect(()=>{ fetchRole(); fetchPages();  console.log("checkboxes: ", checkboxes); },[checkboxes]);

    const fetchRole = async() =>{

        await axios.get(`${BASE_URL}/showRole/${id}`, {
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          },
      }).
        then(({data}) =>
        {
            setRole(data.role);
            data.permissions.forEach(idd => 
            {
                if(!checkboxes.includes(idd.operation_id))
                {
                    checkboxes.push(idd.operation_id);
                    console.log(checkboxes);
                }
                else
                {
                    checkboxes.filter((cur) => cur !== idd.operation_id);
                    console.log(checkboxes);
                }
            });
        })
        .catch(response=>{
            if (response.status == 442) {
                console.log("error");
            }
        });
    }

    const fetchPages = async() =>{

        await axios.get(`${BASE_URL}/pages`, {
            headers:{
              'Authorization': ` Bearer ${passedToken}`
            },
        }).
        then(({data}) =>
        {

            setPages(data);
            data.forEach(pPer =>{
                pPer.operations.forEach(pPerOp =>{
                        pPerOp.key = "check-box"+pPerOp.id;
                        pPerOp.name = "check-box-"+pPerOp.id;
                        pPerOp.input = "check-box-input-"+pPerOp.id;      
                })
            })
        });
    }

    const updateRole = async(e) =>{
        e.preventDefault();
        const formdata = new FormData();
       
        formdata.append("id",id);
        formdata.append("role",role);
        formdata.append("operations",operations);

        await axios.post(`${BASE_URL}/updateRole`, formdata,{
            headers:{
              'Authorization': ` Bearer ${passedToken}`
            },
        }).then((res)=>{
          if(!res.status){
            toast.error('فشل الاتصال بالخادم')
          }
          else if(res.status == 200){
             localStorage.setItem('toastMessage', 'تم تحديث الصلاحيات');
             window.location.replace('/roles');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');

          }
          else if(res.status == 500){
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')

          }
          else if(res.status == 404){
            toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')

          }
          else{
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
          
          }
        }).catch(response=>{
            if (response.status == 442) {
                console.log("error");
            }
        });
    }
    return(
        <div className="Container text-right" dir="rtl">
            <div className="card">
                <div className="card-body">
                    <h1 className="card-title">تعديل صلاحيات المستخدم</h1>
                    <form onSubmit={updateRole}>
                        <div className="mb-3">
                            <label className="form-label"> دور المستخدم </label>
                            <input type="hidden" className="form-control" value={id}/>
                            <input type="text" className="form-control" value={role} onChange={(e)=>setRole(e.target.value)}/>
                        </div> 
                        <Grid
                        container
                        spacing={1}>
                        {pages.length > 0 && (
                                pages.map(page=>(<div>
                            <label className="form-label"><b>{page.page} :</b> </label><br />
                            
                            {
                                page.operations.map((op)=>  
                                { 
                                return(
                                    <div key={op.key}> 
                                    <input  className="form-check-input" type="checkbox" name={op.name} key={op.input} 
                                    value={op.id} 
                                    onChange={(e) => handleChange(e)}
                                    defaultChecked= { checkboxes.includes(op.id)} 
                                    />  
                                    &nbsp;  {op.operation} 
                                    </div>  )  
                                    
                                }
                            
                        )}
                        </div>
                        ))
                        )}
                        </Grid>



                        <div className="mb-3">
                        <button className='btn btn-warning px-3 text-white m-4'> تحديث البيانات </button>
                        </div>
                    </form>
                </div>       
            </div>
        </div>
    );
}

export default EditRole;

