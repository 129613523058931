import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditCancelReason = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const EDIT_TRUCK_QUESTION = `${BASE_URL}/cancelReasons/${id}`;
    
    var accessTokenObj = (localStorage.getItem("token"));
    const passedToken = accessTokenObj;
  
    const [data, setData] = useState({
      ar_name: "",
      en_name: "",
    });

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadList();
      }, []);
      const loadList = async () => {
        const res = await axios.get(EDIT_TRUCK_QUESTION,{ headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      });
        setData(res.data)
      };

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();

        formData.append("ar_name", data.ar_name);
        formData.append("en_name", data.en_name);
        await fetch(EDIT_TRUCK_QUESTION, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}` 
          }
        })
        .then((res)=>{
          console.log(res);
          if(!res.status){
            toast.error('خطأ في الاتصال بالخادم')
            setLoader(false);
          }

          else if(res.status == 500){
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('تأكد من الاتصال بالانترنت و المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 201){
            setLoader(false);
            localStorage.setItem('toastMessage', 'تم تعديل  السبب ');
            window.location.replace('/cancel-reason');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          } 
          loadList();
        })
        .catch(()=>{
          setLoader(false);
          toast.error('حدث خطا الرجاء المحاولة مرة أخرى');
        });
      };
      
      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>

      

      <form onSubmit={handleSubmission}>
        <CardHeader
          // subheader="The information can be edited"
          title="تعديل السبب"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
                        <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="سبب الإلغاء باللغة العربية"
                margin="dense"
                id="ar_name"
                type="text"
                name="ar_name"
                onChange={(e)=> handle(e)}
                value={data.ar_name}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               
                label="سبب الإلغاء باللغة الانجليزية"
                margin="dense"
                id="en_name" 
                type="text" 
                name="en_name"
                onChange={(e)=> handle(e)} 
                value={data.en_name}
                required
                variant="outlined"
                
              />
            </Grid>

        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>


<button className='btn btn-warning text-white px-3  m-4'  >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>تعديل السبب</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditCancelReason.propTypes = {
  className: PropTypes.string
};

export default EditCancelReason;
