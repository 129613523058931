import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// import MoneyIcon from '@material-ui/icons/Money';
import { ShopContext } from 'context/shop-context';
import { ImCancelCircle } from "react-icons/im";


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const CancelledOrder = props => {
  const { className, ...rest } = props;
  const {allCanceledOrders} = useContext(ShopContext);
  const classes = useStyles(); 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
               الطلبات الملغاة
            </Typography>
            <Typography variant="h3">{allCanceledOrders}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {/* <MoneyIcon className={classes.icon} /> */}
              {<ImCancelCircle size={30} />}
            </Avatar>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            7%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  );
};

CancelledOrder.propTypes = {
  className: PropTypes.string
};

export default CancelledOrder;
