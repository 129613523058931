import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
// import Echo from "laravel-echo";
// import io from "socket.io-client";
// window.io = io;


export const ShopContext = createContext(null);

// window.io.Echo = new Echo({
//   broadcaster: 'socket.io',
//   host:  'http://localhost:6001',
//   csrfToken: document.head.querySelector('meta[name="csrf-token"]')
// });


export const ShopContextProvider = (props) => {
  let accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
  
  const [auth, setAuth] = useState({});

  
  const [message, setMessage] = useState([]);

//   window.io.Echo.join('online')
//   .here((users) => {
//       console.log(users);
//       users.forEach(function(user)
//       {
//           if(user.id == 14){return;}
//           console.log(user.name);
         
//       });
//   })
//   .joining((user) => {
//       console.log(user.name + " joining");
     
//   })
//   .leaving((user) => {
//       console.log(user.name + " left");
//   })
//   .error((error) => {
//       console.error(error);
//   });
//   window.io.Echo.channel('chat-group').listen("messageDelivered", (e) => {
//     setMessage (e.chat.message);
// });


  // DAM end piontes 
  const BASE_URL = process.env.REACT_APP_API_URL;

  const CITY_URL = `${BASE_URL}/cities`;
  const CUSTOMERS_URL = `${BASE_URL}/customers`;
  const DRIVERS_URL = `${BASE_URL}/drivers`;
  const COMPANIES_URL = `${BASE_URL}/companies`;
  const AGENTS_URL = `${BASE_URL}/agents`;
  const QUESTIONS_URL = `${BASE_URL}/questions`;
  const USER_URL = `${BASE_URL}/users`;
  const CATEGORIES_URL = `${BASE_URL}/categories`;
  const SERVICES_URL = `${BASE_URL}/getServices`;

  const DELETE_SERVICES_URL = `${BASE_URL}/services`;
  
  const VEHICLES_URL = `${BASE_URL}/vehicles`;
  const PROMOTIONS_URL = `${BASE_URL}/getPromotion`;

  const WELCOMES_URL = `${BASE_URL}/getWelcome`;
  const POLICIES_URL = `${BASE_URL}/policies`;
  const SUB_CATEGORIES_URL = `${BASE_URL}/subCategories`;
  const SUB_CATEGORIES_LIST = `${BASE_URL}/subCategoriesList`; 
  const ORDERS_URL = `${BASE_URL}/requests`;
  const DISCOUNT_URL = `${BASE_URL}/coupons`;
  const PROJECT_URL = `${BASE_URL}/projects`;
  const CONTRUCT_URL = `${BASE_URL}/contracts`;
  const VEHICLE_MODEL_URL = `${BASE_URL}/vehiclesModels`;

  const TRUCK_QUESTIONS = `${BASE_URL}/truckQuestions`;
  const CANCEL_REASON = `${BASE_URL}/cancelReasons`;

  const ROLES_URL = `${BASE_URL}/roles`;
  const DELETE_ROLES_URL = `${BASE_URL}/rmvRole`;

  const APP_SETTINGS = `${BASE_URL}/appSettings`;

  const SEND_OTP_MESSAGE_URL = `${BASE_URL}/sendOtpMessage`;

  const SEND_NOTIFICATION = `${BASE_URL}/sendNotification`;
  

  const MESSAGE_TO_USERS_URL = `${BASE_URL}/sendMessageToUsers`;
  const MESSAGE_TO_AGENTS_URL = `${BASE_URL}/sendMessageToAgents`;
  const MESSAGE_TO_COMPANIES_URL = `${BASE_URL}/sendMessageToCompanies`;
  const MESSAGE_TO_DRIVERS_URL = `${BASE_URL}/sendMessageToDrivers`;
  const MESSAGE_TO_CUSTOMERS_URL = `${BASE_URL}/sendMessageToCustomers`;

  // const NOTIFICATIONS_TO_ALL_USERS = `${BASE_URL}/sendNotificationToAllUsers`;
  const NOTIFICATIONS_TO_ALL_CUSTOMERS = `${BASE_URL}/sendNotificationToAllCustomers`;
  const NOTIFICATIONS_TO_ALL_COMAPANIES = `${BASE_URL}/sendNotificationToAllCompanies`;
  const NOTIFICATIONS_TO_ALL_DRIVERS = `${BASE_URL}/sendNotificationToAllDrivers`;
  const NOTIFICATIONS_TO_ALL_AGENTS = `${BASE_URL}/sendNotificationToAllAgents`;


  const CONVERSATIONS_URL = `${BASE_URL}/conversations`;
  const CHAT_URL = `${BASE_URL}/chats`;
  const MARK_AS_READ_URL = `${BASE_URL}/chats/markAsRead`;
  


  const ALL_ORDERS_URL = `${BASE_URL}/statistics/orders`;
  const ALL_COMPLETED_ORDERS_URL = `${BASE_URL}/statistics/completedOrders`;
  const ALL_CANCELED_ORDERS_URL = `${BASE_URL}/statistics/canceldOrders`;
  const ALL_INPROGRESS_ORDERS_URL = `${BASE_URL}/statistics/inprogressOrders`;

  const CANCEL_BY_CUSTOMER = `${BASE_URL}/ordersCanceledByCustomers`;
  const CANCEL_BY_DRIVER = `${BASE_URL}/ordersCanceledByDrivers`;
  const CANCEL_BY_SYSTEM = `${BASE_URL}/ordersCanceledBySystem`;

  const ACTIVE_CITY = `${BASE_URL}/activeCities`;
  const INACTIVE_CITY = `${BASE_URL}/inactiveCities`;

  const HEAVY_MACHINE_URL = `${BASE_URL}/heavyMachinesList`;
  const HEAVY_MACHINE_SIZE_URL = `${BASE_URL}/heavyMachineSizes`;
  const ACTIVATE_HEAVY_MACHINE = `${BASE_URL}/heavyMachines`;

  const ARCHIVED_USER_URL = `${BASE_URL}/archivedUsers`;

  const ARCHIVE_USER_URL = `${BASE_URL}/archive`;
  const UNARCHIVE_USER_URL = `${BASE_URL}/unarchive`;

  const AGENT_DRIVER_LIST = `${BASE_URL}/agentDriversList`;
  
 

  const userLoggedOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("isLoggedIn");
    window.location.replace('/sign-in');
  }


    const [city, setCity] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [agents, setAgents] = useState([]);
    const [questions, setQuestions] = useState([]);

    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [welcomes, setWelcomes] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [orders, setOrders] = useState([]);
    const [discount, setDiscount] = useState([]);
    const [users, setUsers] = useState([]);
    const [heavyMachine, setHeavyMachine] = useState([]);
    const [heavyMachineSize, setHeavyMachineSize] = useState([]);

    const [driver, setDriver] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [agent, setAgent] = useState([]);
    const [company, setCompany] = useState([]);

    const [projects, setProjects] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [vehiclesModels, setVehiclesModels] = useState([]);
    const [archivedUsers, setArchivedUsers] = useState([]);

    const [truckQuestion, setTruckQuestion] = useState([]);
    const [cancelReason, setCancelReason] = useState([]);

    const [appSetting, setAppSetting] = useState([]);

    const [agentDriversList, setAgentDriverList] = useState([]);

    const [roles, setRoles] = useState([]);
    

    const [conversations, setConversations] = useState([]);
    const [chats, setChats] = useState([]);

    const [customerLen, setCustomerLen] = useState(0);
    const [driverLen, setDriverLen] = useState(0);
    const [companyLen, setCompanyLen] = useState(0);
    const [agentLen, setAgentLen] = useState(0);
    const [projectLen, setProjectLen] = useState(0);


    const [allOrders, setAllOrders] = useState(0);
    const [allCompletedOrders, setAllCompletedOrders] = useState(0);
    const [allCanceledOrders, setAllCanceledOrders] = useState(0);
    const [allInProgressOrders, setAllInProgressOrders] = useState(0);

    const [ordersCanceledByCustomers, setOrdersCanceledByCustomers] = useState(0);
    const [ordersCanceledByDrivers, setOrdersCanceledByDrivers] = useState(0);
    const [ordersCanceledBySystem, setOrdersCanceledBySystem] = useState(0);

    const [activeCities, setActiveCities] = useState(0);
    const [inactiveCities, setInactiveCities] = useState(0);



    const [customerSearch, setCustomerSearch] = useState("");
    const [companySearch, setCompanySearch] = useState("");
    const [driverSearch, setDriverSearch] = useState("");
    const [agentSearch, setAgentSearch] = useState("");
    const [serviceSearch, setServiceSearch] = useState("");
    const [orderSearch, setOrderSearch] = useState("");

    const [userSearch, setUserSearch] = useState("");
    const [projectSearch, setProjectSearch] = useState("");
    const [contructSearch, setContructSearch] = useState("");
    const [heavyMachineSearch, setheavyMachineSearch] = useState("");

    const [readMsg, setReadMsg] = useState(false);

//Get Cities

    useEffect(() => {
      axios.get(CITY_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setCity(res.data);
  
      }).catch(err => {
        console.log(err)
      })
    }, []);


    useEffect(() => {
      axios.get(USER_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then((res) => {
        setUsers(res.data);
  
      }).catch((err) => {
        console.log(err);
      })
    }, [])
  

    useEffect(() => {
      axios.get(CUSTOMERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setCustomers(res.data);
        setCustomerLen(res.data.total);
      }).catch(err => {
        console.log(err)
      })
    }, []);


    console.log('Customer length', customerLen );


    useEffect(() => {
      axios.get(DRIVERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setDrivers(res.data);
        setDriverLen(res.data.total);
      }).catch(err => {
        console.log(err)
      })
    }, []);


    const getDriver = async (e, id) =>{
      e.preventDefault();
     await axios.get(`${DRIVERS_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }).then(async (res)=>{
         setDriver(res.data);
      }).catch((err)=>{
        console.log(err);
      })
    }

    const getCustomer = async (e, id) =>{
      e.preventDefault();
     await axios.get(`${CUSTOMERS_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }).then(async (res)=>{
         setCustomer(res.data);
      }).catch((err)=>{
        console.log(err);
      })
    }

    const getAgent = async (e, id) =>{
      e.preventDefault();
     await axios.get(`${AGENTS_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }).then(async (res)=>{
         setAgent(res.data);
      }).catch((err)=>{
        console.log(err);
      })
    }

    const getCompany = async (e, id) =>{
      e.preventDefault();
     await axios.get(`${COMPANIES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }).then(async (res)=>{
         setCompany(res.data);
      }).catch((err)=>{
        console.log(err);
      })
    }
    

    useEffect(() => {
      axios.get(COMPANIES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setCompanies(res.data);
        setCompanyLen(res.data.total);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(AGENTS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAgents(res.data);
        setAgentLen(res.data.total);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    
    useEffect(() => {
      axios.get(QUESTIONS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setQuestions(res.data);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(CATEGORIES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setCategories(res.data);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(SERVICES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setServices(res.data.data);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(VEHICLES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setVehicles(res.data);
      }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(PROMOTIONS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setPromotions(res.data);
 }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(POLICIES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setPolicies(res.data);
        }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(WELCOMES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setWelcomes(res.data);
   }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(SUB_CATEGORIES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setSubCategories(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(SUB_CATEGORIES_LIST, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setSubCategoriesList(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ORDERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setOrders(res.data.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(DISCOUNT_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setDiscount(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(AGENT_DRIVER_LIST, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAgentDriverList(res.data.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(PROJECT_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setProjects(res.data);
        setProjectLen(res.data.total);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(CONTRUCT_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {2
        setContracts(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(VEHICLE_MODEL_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setVehiclesModels(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ARCHIVED_USER_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setArchivedUsers(res.data.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(HEAVY_MACHINE_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setHeavyMachine(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(HEAVY_MACHINE_SIZE_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setHeavyMachineSize(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(TRUCK_QUESTIONS, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setTruckQuestion(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(CANCEL_REASON, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setCancelReason(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(APP_SETTINGS, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAppSetting(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ROLES_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setRoles(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);


    useEffect(() => {
      axios.get(CONVERSATIONS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setConversations(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, [message]);

    useEffect(() => {
      axios.get(CHAT_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setChats(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, [message]);

    useEffect(() => {
      axios.get(ALL_ORDERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAllOrders(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ALL_COMPLETED_ORDERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAllCompletedOrders(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ALL_CANCELED_ORDERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAllCanceledOrders(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(ALL_INPROGRESS_ORDERS_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setAllInProgressOrders(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);



    useEffect(() => {
      axios.get(CANCEL_BY_CUSTOMER, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setOrdersCanceledByCustomers(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(CANCEL_BY_DRIVER, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setOrdersCanceledByDrivers(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(CANCEL_BY_SYSTEM, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setOrdersCanceledBySystem(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);



    useEffect(() => {
      axios.get(ACTIVE_CITY, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setActiveCities(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);

    useEffect(() => {
      axios.get(INACTIVE_CITY, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setInactiveCities(res.data);
       }).catch(err => {
        console.log(err)
      })
    }, []);


 //Delete City
 const deleteCity = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف المدينة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
 

      del.innerText = "جار الحذف...";
      await axios.delete(`${CITY_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف المدينة بنجاح", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
        

      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

 //Delete User
 const deleteUser = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف المدينة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${USER_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف المستخدم ", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

//Delete Driver
const deleteDriver = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف الحساب ",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
 

      del.innerText = "جار الحذف...";
      await axios.delete(`${DRIVERS_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف بيانات الحساب", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

//Delete Customer
const deleteCustomer = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف بيانات العميل",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${CUSTOMERS_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف بيانات العميل", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
        

      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}


//Delete Company
const deleteCompany = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف بيانات الشركة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${COMPANIES_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف بيانات الشركة", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
        

      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

//Delete Agent
const deleteAgent = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف بيانات الوكيل",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${AGENTS_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف بيانات الوكيل", {
            icon: "success"
          });
          // window.location.replace('/cities')
        }
        

      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

//Delete Question
const deleteQuestion = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف السؤال",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
 
      del.innerText = "جار الحذف...";
      await axios.delete(`${QUESTIONS_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف السؤال ", {
            icon: "success"
          });
        }
      });
    }
  }).catch(err => {
    console.log('the error',err);
  })
}

  //Delete Category
  const deleteCategory = async (e, id) => {
    const del = e.currentTarget;
    swal({
      title: "حذف القسم",
      text: "هل أنت متأكد من القيام بهذه العملية؟",
      icon: "warning",
      // buttons: true,
      buttons: ["إلغاء", "تأكيد"],
      dangerMode: true,

    }).then(async (willDelete) => {
      if (willDelete) {
 

        del.innerText = "جار الحذف...";
        await axios.delete(`${CATEGORIES_URL}/${id}`,
          {
            headers: {
              'Authorization': ` Bearer ${passedToken}`
            }
          }
        ).then(res => {
          if (res.status === 200) {
            del.closest("tr").remove();
            swal("تم حذف القسم", {
              icon: "success"
            });
            window.location.replace('/category')
          }
          
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }

//Delete Service
const deleteService = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف القسم",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${DELETE_SERVICES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف القسم", {
            icon: "success"
          });
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}
const deleteDiscount = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف كود الخصم",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${DISCOUNT_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم حذف كود الخصم", {
            icon: "success"
          });
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

//Delete Promotion
const deletePromotion = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف القسم",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${PROMOTIONS_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          swal("تم حذف القسم", {
            icon: "success"
          });
          del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
} 

//Delete Sub Category
const deleteSubCategory = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف القسم الفرعي",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${SUB_CATEGORIES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          swal("تم حذف القسم", {
            icon: "success"
          });
          del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const deleteWelcome= async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف الشاشة الترحبية",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {


      del.innerText = "جار الحذف...";
      await axios.delete(`${WELCOMES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
} 

const deletePolicy = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف سياسة الخصوصية",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {

      

      del.innerText = "جار الحذف...";
      await axios.delete(`${POLICIES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
} 

const deleteModel = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف سياسة الخصوصية",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${VEHICLE_MODEL_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteHeavyMachine = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف المعدة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${ACTIVATE_HEAVY_MACHINE}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteHeavyMachineSize = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف الحجم",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${HEAVY_MACHINE_SIZE_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const deleteTruckQuestion = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف السؤال",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${TRUCK_QUESTIONS}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteCancelReason = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف السبب",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${CANCEL_REASON}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteAppSetting = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف الإعدادات",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${APP_SETTINGS}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteRole = async (e, id) => {
  const del = e.currentTarget;
  swal({
    title: "حذف الدور",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    // buttons: true,
    buttons: ["إلغاء", "تأكيد"],
    dangerMode: true,

  }).then(async (willDelete) => {
    if (willDelete) {
      
      del.innerText = "جار الحذف...";
      await axios.delete(`${DELETE_ROLES_URL}/${id}`,
        {
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          del.closest("tr").remove();
          swal("تم الحذف ", {
            icon: "success"
          });
    
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const driverArchive = async (e, id) => {
  // const del = e.currentTarget;
  swal({
    title: "أرشفة السائق",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {


      // del.innerText = "جار أرشفة بيانات السائق...";
      await fetch(`${DRIVERS_URL}/archive/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
               swal("تمت عملية الأرشفة", {
        icon: "success"
      });
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
} 

const driverActivate= async (e, id) => {
  // const del = e.currentTarget;
  swal({
    title: "تفعيل حساب السائق",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تم تفعيل الحساب", {
        icon: "success"
      });

      // del.innerText = "جار أرشفة بيانات السائق...";
      await fetch(`${DRIVERS_URL}/activate/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          // del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const driverInActivate= async (e, id) => {
  e.preventDefault();
  swal({
    title: "إلغاء تفعيل حساب السائق",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تم إلغاء تفعيل الحساب", {
        icon: "success"
      });

      // del.innerText = "جار أرشفة بيانات السائق...";
      await fetch(`${DRIVERS_URL}/inActivate/${id}`,
        {
          method: 'POST',
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          // del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const aprroveChange= async (e, id) => {
  // const del = e.currentTarget;
  swal({
    title: "الموافقة على التعديلات",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تمت الموافقة على  التعديلات", {
        icon: "success"
      });

      // del.innerText = "جار أرشفة بيانات السائق...";
      await fetch(`${DRIVERS_URL}/activate/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          // del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const agentArchive = async (e, id) => {
  // const del = e.currentTarget;
  swal({
    title: "أرشفة الشركة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تمت عملية الأرشفة", {
        icon: "success"
      });

      // del.innerText = "جار أرشفة بيانات السائق...";
      await fetch(`${AGENTS_URL}/archive/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          // del.closest("tr").remove();
        }
        

      });
    }
  }).catch(err => {
    console.log(err);
  })
} 

const agentInActivate= async (e, id) => {
  swal({
    title: "إلغاء تفعيل حساب الشركة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,
  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تم إلغاء تفعيل الحساب", {
        icon: "success"
      });
      await fetch(`${AGENTS_URL}/inActivate/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          console.log('Account inactivated successfully');
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const agentActivate= async (e, id) => {
  swal({
    title: " تفعيل حساب الشركة",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تم تفعيل الحساب", {
        icon: "success"
      });
      await fetch(`${AGENTS_URL}/activate/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const aprroveAgentChange= async (e, id) => {

  swal({
    title: "الموافقة على التعديلات",
    text: "هل أنت متأكد من القيام بهذه العملية؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    dangerMode: true,

  }).then(async (willArchive) => {
    if (willArchive) {
      swal("تمت الموافقة على  التعديلات", {
        icon: "success"
      });

     
      await fetch(`${AGENTS_URL}/activate/${id}`,
        {
          method: 'POST',
          headers: {
            'Authorization': ` Bearer ${passedToken}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          console.log('change approved');
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendOtpMessage= async (e, phone) => {
  e.preventDefault();
  let message ;
    
      swal(`رقم الهاتف ${phone} `, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      const formData = new FormData();
      formData.append("phone", phone);
      formData.append("message", message);

      await fetch(`${SEND_OTP_MESSAGE_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          toast.success('تم الإرسال');
        }
        else if (res.status === 404) {
          toast.error('حدث خطا في الارسال');
        }

      

      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendNotification= async (e, id) => {
  e.preventDefault();
  let message;
      swal('إشعارات التطبيق ',{
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
     
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${SEND_NOTIFICATION}/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          toast.success('تم الإرسال');
        }
        else if (res.status === 404) {
          toast.error('حدث خطا في الارسال');
        }

        else if (res.status === 500) {
          toast.error('حدث خطا في الارسال');
        }

      });
    }
  }).catch(err => {
    console.log(err);
  })
}




const sendMessageToAgents= async (e) => {
  e.preventDefault();
  let message ;
    
      swal(`رسالة جماعية للوكلاء`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${MESSAGE_TO_AGENTS_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendMessageToUsers= async (e) => {
  e.preventDefault();
  let message ;
      swal(`رسالة جماعية للمستخدمين`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${MESSAGE_TO_USERS_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendMessageToCompanies= async (e) => {
  e.preventDefault();
  let message ;
      swal(`رسالة جماعية للشركات`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${MESSAGE_TO_COMPANIES_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendMessageToDrivers= async (e) => {
  e.preventDefault();
  let message ;
      swal(`رسالة جماعية للسائقين`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${MESSAGE_TO_DRIVERS_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendMessageToCustomers= async (e) => {
  e.preventDefault();
  let message ;
      swal(`رسالة جماعية للعملاء`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${MESSAGE_TO_CUSTOMERS_URL}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}



const sendNotificationToCustomers= async (e) => {
  e.preventDefault();
  let message ;
      swal(`إشعار جماعي للعملاء`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${NOTIFICATIONS_TO_ALL_CUSTOMERS}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendNotificationToComapnies= async (e) => {
  e.preventDefault();
  let message ;
      swal(`إشعار جماعي للشركات`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${NOTIFICATIONS_TO_ALL_COMAPANIES}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendNotificationToDrivers= async (e) => {
  e.preventDefault();
  let message ;
      swal(`إشعار جماعي للسائقين`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${NOTIFICATIONS_TO_ALL_DRIVERS}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendNotificationToAgents= async (e) => {
  e.preventDefault();
  let message ;
      swal(`إشعار جماعي للوكلاء`, {
        content: "input",
        buttons: "إرسال"
      }).then(async (value) => {
    if (value) {
      message = value;
      toast.success('تم الإرسال');
      const formData = new FormData();
      formData.append("message", message);

      await fetch(`${NOTIFICATIONS_TO_ALL_AGENTS}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
         console.log('Message sent successfully');
        }
      });
    }
  }).catch(err => {
    console.log(err);
  })
}


const [chatMessages, setChatMessages] = useState([]);
const [chatSender, setChatSender] = useState([]);

const getSenderData= async (e, id) => {
  e.preventDefault();

      await axios.get(`${CONVERSATIONS_URL}/senderData/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          setChatSender(res.data);
        } 

      });
    }

useEffect(()=>{
  getSenderData();
},[chatMessages]);


const getChatMessages = async (id) => {
  // e.preventDefault();
      await axios.get(`${CONVERSATIONS_URL}/${id}`, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          setChatMessages(res.data);
        } 

      });
    }

    const setMessageAsRead = async (e, message_id) => {
      e.preventDefault();
       await fetch(`${MARK_AS_READ_URL}/${message_id}`, {
        method:'POST',
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        if (res.status === 200) {
          setReadMsg(false);
        } 
      }).catch(err=>{
        console.log(err);
      })
    }
  
    const heavyMachineActivate = async (e, id) => {
      swal({
        title: " تفعيل  المعدة",
        text: "هل أنت متأكد من القيام بهذه العملية؟",
        icon: "warning",
        buttons: ["إلغاء", "نعم"],
        dangerMode: true,
    
      }).then(async (willArchive) => {
        if (willArchive) {
          swal("تم تفعيل المعدة", {
            icon: "success"
          });
         
          await fetch(`${ACTIVATE_HEAVY_MACHINE}/activate/${id}`,
            {
              method: 'POST',
              headers: {
                'Authorization': ` Bearer ${passedToken}`
              }
            }
          ).then(res => {
            
            if (res.status === 200) {
              window.location.replace('/heavy-machine')
              
            }
          });
        }
      }).catch(err => {
        console.log(err);
      })
    }

    const heavyMachineInactivate = async (e, id) => {
      swal({
        title: " الغاء تفعيل  المعدة",
        text: "هل أنت متأكد من القيام بهذه العملية؟",
        icon: "warning",
        buttons: ["إلغاء", "نعم"],
        dangerMode: true,
    
      }).then(async (willArchive) => {
        if (willArchive) {
          swal("تم الغاء تفعيل المعدة", {
            icon: "success"
          });
         
          await fetch(`${ACTIVATE_HEAVY_MACHINE}/inactivate/${id}`,
            {
              method: 'POST',
              headers: {
                'Authorization': ` Bearer ${passedToken}`
              }
            }
          ).then(res => {
            
            if (res.status === 200) {
              window.location.replace('/heavy-machine')
              
            }
          });
        }
      }).catch(err => {
        console.log(err);
      })
    }

    const userArchive = async (e, id) => {
      const del = e.currentTarget;
      swal({
        title: "أرشفة الحساب",
        text: "هل أنت متأكد من القيام بهذه العملية؟",
        icon: "warning",
        buttons: ["إلغاء", "نعم"],
        dangerMode: true,
    
      }).then(async (willArchive) => {
        if (willArchive) {
         
          del.innerText = "جار أرشفة بيانات الحساب...";
          await fetch(`${ARCHIVE_USER_URL}/${id}`,
            {
              method: 'POST',
              headers: {
                'Authorization': ` Bearer ${passedToken}`
              }
            }
          ).then(res => {
            if (res.status === 201) {
              del.closest("tr").remove();
              swal("تمت عملية الأرشفة", {
                icon: "success"
              });
              window.location.replace('/archived-user');
            }
          });
        }
      }).catch(err => {
        console.log(err);
      })
    } 

    const userUnarchive = async (e, id) => {
      const del = e.currentTarget;
      swal({
        title: "إلغاء أرشفة الحساب" ,
        text: "هل أنت متأكد من القيام بهذه العملية؟",
        icon: "warning",
        buttons: ["إلغاء", "نعم"],
        dangerMode: true,
    
      }).then(async (willArchive) => {
        if (willArchive) {
         
          del.innerText = "جار  إلغاء أرشفة بيانات الحساب...";
          await fetch(`${UNARCHIVE_USER_URL}/${id}`,
            {
              method: 'POST',
              headers: {
                'Authorization': ` Bearer ${passedToken}`
              }
            }
          ).then(res => {
            if (res.status === 201) {
                 del.closest("tr").remove();
              swal("تمت عملية إلغاء الأرشفة", {
                icon: "success"
              });
              //window.location.replace('/archived-user');
           
            }
          });
        }
      }).catch(err => {
        console.log(err);
      })
    } 

    const [data, setData] = useState([]);

    const getAgentDrivers = (e, id) => {
      e.preventDefault();

    axios.get(`${AGENT_DRIVER_LIST}/${id}`, {
      headers :{
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then((res)=>{
      console.log(res.data.data);
      setData(res.data.data);
    })
    }

    function convertUtcToLocal(utcTime) {
      const utcDate = new Date(utcTime);
      const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    
      return localDate;
    }
    

  const contextValue = {
    auth,
    setAuth,
    userLoggedOut,
    users,
    deleteCategory,
    city,
    customers,
    drivers,
    companies,
    agents,
    questions,
    services,
    categories,
    vehicles,
    promotions,
    discount,
    projects,
    contracts,
    vehiclesModels,
    heavyMachine,
    heavyMachineSize,

    conversations,
    chats,
    chatSender,
    chatMessages,
    getChatMessages,
    getSenderData,

    deleteCity,
    deleteCustomer,
    deleteDriver,
    deleteCompany,
    deleteAgent,
    deleteQuestion,
    deleteService,
    deletePromotion,
    deleteDiscount,
    deleteUser,
    driverArchive,
    driverActivate,
    driverInActivate,
    aprroveChange,
    sendOtpMessage,
    sendNotification,
    agentArchive,
    agentActivate,
    agentInActivate,
    aprroveAgentChange,
    welcomes,
    policies,
    deleteWelcome,
    deletePolicy,
    subCategories,
    subCategoriesList,
    orders,
    deleteSubCategory,
    deleteModel,
    customerLen,
    driverLen,
    companyLen,
    agentLen,
    projectLen,
    allOrders,
    allCompletedOrders,
    allCanceledOrders,
    allInProgressOrders,

    ordersCanceledByCustomers,
    ordersCanceledByDrivers,
    ordersCanceledBySystem,
    activeCities,
    inactiveCities,

    getDriver,
    getCustomer,
    getAgent,
    getCompany,
    driver,
    customer,
    agent,
    company,
    archivedUsers,
  
    heavyMachineActivate,
    heavyMachineInactivate,
    deleteHeavyMachine,
    deleteHeavyMachineSize,
    sendMessageToAgents,
    sendMessageToUsers,
    sendMessageToCompanies,
    sendMessageToDrivers,
    sendMessageToCustomers,

    userArchive,
    userUnarchive,
    agentDriversList,
    getAgentDrivers,
    data,

    customerSearch,
    companySearch,
    driverSearch,
    agentSearch,
    serviceSearch,
    orderSearch,
    userSearch,
    projectSearch,
    contructSearch,
    heavyMachineSearch,
    setCustomerSearch,
    setCompanySearch,
    setDriverSearch,
    setAgentSearch,
    setServiceSearch,
    setOrderSearch,
    setUserSearch,
    setProjectSearch,
    setContructSearch,
    setheavyMachineSearch,
    truckQuestion,
    deleteTruckQuestion,
    cancelReason,
    deleteCancelReason,
    appSetting,
    deleteAppSetting,
    setMessage,
    message,
    setMessageAsRead,
    convertUtcToLocal,
    readMsg,
    setReadMsg,
    roles,
    deleteRole,

    sendNotificationToCustomers,
    sendNotificationToComapnies,
    sendNotificationToDrivers,
    sendNotificationToAgents,

  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};