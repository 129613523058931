import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditPromotion = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams(); 
    const [img, setImg] = useState('');

    const BASE_URL = process.env.REACT_APP_API_URL;
    const PROMOTION_URL = `${BASE_URL}/promotions/${id}`; 
    
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
      const res = await axios.get(PROMOTION_URL,{ headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    });
      setData(res.data);
      setImg(res.data.image)
      console.log(res.data.image);
    }

    const [data, setData] = useState({
      link : "",
      ordering : 0,
    });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

    const handleSubmission = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", selectedFile || img );
      formData.append("link", data.link);
      formData.append("ordering", data.ordering);
      formData.append("is_active", data.is_active);
      await fetch(PROMOTION_URL, {
        method: "POST",
        body: formData,
        headers:{
          'Authorization': ` Bearer ${passedToken}` 
        }
      })
      .then((res)=>{
        setLoader(false);
        if(!res.status){
          toast.error('فشل الاتصال بالخادم الرجاء المحاولة مرة أخرى');
        }

        else if(res.status == 500){
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        }
        else if(res.status == 404){
          setLoader(false);
          toast.error('تأكد من الاتصال و المحاولة مرة أخرى');
        }
        else if(res.status == 201){
          setLoader(false);
            localStorage.setItem('toastMessage', 'تم تعديل الإعلان  ');
            window.location.replace('/promotions');
        }
        else if(res.status == 401){
          toast.error('وصول غير مصرح به');
          setLoader(false);
        }
        else{
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        } 
        loadList();
      })
      .catch(()=>{
        toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        setLoader(false);
      });
    };

    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
    }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
        title="تعديل الإعلان"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="رابط الإعلان"
                label="رابط الإعلان"
                margin="dense"
                name="link"
                id="link"
                onChange={(e)=> handle(e)} 
                value={data.link}
                variant="outlined"
              />
            </Grid>
      
            <Grid
              item
              md={6}
              xs={12}
            >
    
            
              <TextField
                fullWidth
                label="ترتيب الإعلان "
                helperText="ترتيب الإعلان"
                margin="dense"
                name="ordering"
                id="ordering"
                onChange={(e)=> handle(e)} 
                value={data.ordering}
                type="number"
               required
                variant="outlined"
              />
            </Grid>
  
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="حالة الإعلان"
                helperText="أختر الصنف بالعربي"
                margin="dense"
                id="is_active"
                name="is_active"
                onChange={(e)=> handle(e)} value={data.is_active}
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
             <option value={1}>مفعل </option>
             <option value={0}>غير مفعل </option>
              </TextField>

            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
          
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>

<button className='btn btn-warning px-3  text-white m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> تعديل البيانات</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditPromotion.propTypes = {
  className: PropTypes.string
};

export default EditPromotion;
