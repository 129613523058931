import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CompaniesToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const {sendMessageToCompanies, sendNotificationToComapnies, setCompanySearch} = useContext(ShopContext);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
         <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(e)=> sendMessageToCompanies(e)}
          >
        إرسال رسالة جماعية
        </Button>
        </div>
        <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(e)=> sendNotificationToComapnies(e)}
          >
        إرسال إشعار جماعي
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="البحث"
          onChange={(e)=> setCompanySearch(e.target.value)}
        />
      </div>
    </div>
  );
};

CompaniesToolbar.propTypes = {
  className: PropTypes.string
};

export default CompaniesToolbar;
