import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import axios from 'axios';
import ReactPaginate from "react-paginate";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));



const LatestOrders = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const BASE_URL = process.env.REACT_APP_API_URL;
    const MESSAGE_URL = `${BASE_URL}/contracts`;

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  useEffect(() => {
    fetchData(currentPage);
  }, []);
 
  const fetchData = (pageNumber) => {
    axios
      .get(`${MESSAGE_URL}?perPage=5&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then((response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (data) => {
    let selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="طلبات المعدات"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
              <TableRow >
              <TableCell align='right'>الرقم</TableCell>
             <TableCell className='d-flex align-items-start'>اسم الشركة</TableCell>
             
             <TableCell align='right'>اسم المفوض</TableCell>
             <TableCell align='right'>رقم الهاتف</TableCell>
             <TableCell align='right'>عدد المعدات</TableCell>
             <TableCell align='right'>الموديل</TableCell>
             <TableCell align='right'>نوع الالية</TableCell>
             <TableCell align='right'> حجم الالية</TableCell>
             <TableCell align='right'>الملاحظات</TableCell>
             <TableCell align='right'>التاريخ</TableCell>

           </TableRow>
              </TableHead>
              <TableBody>
                {data.map(item => (
                  <TableRow
                    hover
                    key={item.id}
                  >
                     <TableCell align='right'>{item.id}</TableCell>
                    <TableCell className='d-flex align-items-start'>{item.company_name}</TableCell>
                   
                    <TableCell align='right'>{item.name}</TableCell>
                    <TableCell align='right' dir='ltr'>{item.phone}</TableCell>
                    <TableCell align='right'>{item.number}</TableCell>
                    <TableCell align='right'>{item.model}</TableCell>
                    <TableCell align='right'>{item.service_name}</TableCell>
                    <TableCell align='right'>{item.size}</TableCell>
                    <TableCell align='right'>{item.notes}</TableCell>
                    <TableCell align='right' dir='ltr'>{moment(item.created_at).format('DD-MM-YYYY hh:mm A')}</TableCell>
    
                  </TableRow>
                  
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions} dir='rtl'>
        {/* <TablePagination
          component="div"
          count={data.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        /> */}
        <div className='pagination' dir='ltr'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
