import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddQuestion = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const QUESTION_URL = `${BASE_URL}/questions`;
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
console.log(passedToken);
  const [arQuestion, setArQuestion] = useState("");
  const [enQuestion, setEnQuestion] = useState("");
  const [arAnswer, setArAnswer] = useState("");
  const [enAnswer, setErAnswer] = useState("");


  const [loader, setLoader] = useState(false);
 
  const handleSubmission =  (e) => {
    e.preventDefault();
      setLoader(true);
      const formData = new FormData();
      formData.append("ar_question", arQuestion);
      formData.append("en_question", enQuestion);
      formData.append("ar_answer", arAnswer);
      formData.append("en_answer", enAnswer);
       fetch(QUESTION_URL, {
        method: "POST",
        body: formData,
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }})
      .then((res)=>{
          if(!res.status){
            toast.error('فشل الاتصال بالخادم')
            setLoader(false);
          
          }
          else if(res.status == 201){
            setLoader(false);
             localStorage.setItem('toastMessage', 'تم الإضافة ');
             window.location.replace('/quesions');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else if(res.status == 500){
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
      })
      .catch(()=>{
        toast.error('حدث خطأ في الإدخال حاول مرة أخرى ');
        setLoader(false);
       
      });
    };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
          title="إضافة الأسئلة الشائعة"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="السؤال باللغة العربية"
                label="السؤال باللغة العربية"
                margin="dense"
                id="arQuestion"
                type="text" 
                name="arQuestion"
                onChange={(e) => setArQuestion(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>
              
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="الإجابة باللغة العربية"
                label="الإجابة باللغة العربية"
                margin="dense"
                id="arAnswer"
                type="text" 
                name="arAnswer"
                onChange={(e) => setArAnswer(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>


            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                helperText="السؤال باللغة الإنجليزية"
                label="السؤال باللغة الإنجليزية"
                margin="dense"
                id="enQuestion"
                type="text"
                name="enQuestion"
                onChange={(e) => setEnQuestion(e.target.value)} 
                required
                variant="outlined"
                
              />
              </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                helperText="الإجابة باللغة الإنجليزية"
                label="الإجابة باللغة الإنجليزية"
                margin="dense"
                id="enAnswer"
                type="text"
                name="enAnswer"
                onChange={(e) => setErAnswer(e.target.value)} 
                required
                variant="outlined"
                
              />
            </Grid>
       
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
       

<button className='btn btn-warning px-3 text-white m-4'  >

  
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إضافة السؤال</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddQuestion.propTypes = {
  className: PropTypes.string
};

export default AddQuestion;
