import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddUser = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const {city, roles}= useContext(ShopContext);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const USERS_URL = `${BASE_URL}/users/register`;
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
console.log(passedToken);
const [data, setData] = useState({
  name : "",
  password : "",
  password_confirmation : "",
  phone : 0,
  // cat_id : 0 
});

  const [loader, setLoader] = useState(false);
 
  const handleSubmission =  (e) => {
    e.preventDefault();
      setLoader(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("password", data.password);
      formData.append("password_confirmation", data.password_confirmation);
      formData.append("address_id", data.city_id);
      formData.append("role_id", data.role_id);
       fetch(USERS_URL,{
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        },
      
        method: "POST",
        body: formData
      })
      .then((res)=>{
          if(!res.status){
            toast.error('فشل الاتصال بالخادم')
            setLoader(false);
          
          }
          else if(res.status == 200){
            setLoader(false);
             localStorage.setItem('toastMessage', 'تم إضافة المستخدم ');
             window.location.replace('/users');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else if(res.status == 500){
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
      })
      .catch(()=>{
        toast.error('حدث خطأ في الإدخال حاول مرة أخرى ');
        setLoader(false);
       
      });
    };
    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
    }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
          title="أضافة مستخدم"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
              
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                label="اسم المستخدم"
                margin="dense"
                id="name"
                type="text"
                name="name"
               onChange={(e)=> handle(e)} 
                value={data.name}
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="رقم الهاتف"
                margin="dense"
                id="phone"
                type="number" 
                name="phone"
                onChange={(e)=> handle(e)} 
                value={data.phone}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                label="كلمة المرور"
                margin="dense"
                id="password"
                type="password"
                name="password"
               onChange={(e)=> handle(e)} 
                value={data.password}
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                label="تاكيد كلمة المرور"
                margin="dense"
                id="password_confirmation"
                type="password"
                name="password_confirmation"
               onChange={(e)=> handle(e)} 
                value={data.password_confirmation}
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // label="أختر الصنف"
                helperText="الصنف بالعربي"
                margin="dense"
                id="city_id"
                name="city_id"
                onChange={(e)=> handle(e)} 
                value={data.city_id}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                   <option value={null}>حدد المدينة</option>
                {city.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>
              {/* {showArError && <span className='text-danger'>هذا الحقل اجباري</span>} */}
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // label="أختر الصنف"
                helperText="الصنف بالعربي"
                margin="dense"
                id="role_id"
                name="role_id"
                onChange={(e)=> handle(e)} 
                value={data.role_id}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                   <option value={null}>حدد الدور</option>
                {roles.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.role}
                  </option>
                ))}
              </TextField>
            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
       

<button className='btn btn-warning px-3 text-white m-4'  >

  
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>اضافة المستخدم</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddUser.propTypes = {
  className: PropTypes.string
};

export default AddUser;
