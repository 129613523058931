import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { FaFileInvoiceDollar } from "react-icons/fa";


import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField
} from '@material-ui/core';

import { ShopContext } from 'context/shop-context';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function OrdersTable(props) {

  const BASE_URL = process.env.REACT_APP_API_URL;
  const ORDER_URL = `${BASE_URL}/requests`;
  
  const { className, ...rest } = props;
  const { city, orderSearch, convertUtcToLocal} = useContext(ShopContext);

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
 
  // const [filters, setFilters] = useState(false);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOrderCounts, setOrderPageCounts] = useState(0);
  const [msg , setMsg] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [leng , setLeng] = useState(0);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);

  const [OrderCurrentPage, setOrderCurrentPage] = useState(0);

  
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedOrder, setSelectedOrder] = useState();


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);
  const [filteredOrderData, setFilteredOrderData] = useState([]);

  const classes = useStyles();
  const [item, setItem] = useState([]);
  useEffect(()=>{
    setItem(data);
   
  },[data])


  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetchSearchData(currentPage);
  },[orderSearch]);

  useEffect(() => {
    fetchFilteredOrder(OrderCurrentPage);
    
  }, [selectedOrder]);
  

  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);
  
 
  const fetchData =  async (pageNumber) => {
    await axios.get(`${ORDER_URL}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSearchData =  async (pageNumber) => {
    if (!orderSearch) {
      fetchData(pageNumber);
    }
    await axios.get(`${ORDER_URL}/search/${orderSearch}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

    const fetchFilteredOrder =   async (pageNumber) => {
      const post = { status: selectedOrder }
      await axios.post(`${ORDER_URL}/filter/getFilterData?perPage=10&page=${pageNumber}`, post, { 
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }}).then((response)=>{
          setFilteredOrderData(response.data.data);
          setOrderPageCounts(response.data.last_page);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      };

const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${ORDER_URL}/city/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then(  (response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    setLeng(response.data.data.length);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};

  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };

  const hnadleFilteredOrderPageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setOrderCurrentPage(selectedPage);
    fetchFilteredOrder(selectedPage);
  };



const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
  
}

function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
  setSelectedOrder(false);
}

function handleOrderChange(event) {
  setSelectedOrder(event.target.value);
  setSelectedCategory(false);
}



function getFilteredList() {
  if (selectedCategory) {
    setMsg(false);
    setFilterCurrentPage(0);
    return filteredData;
  }
  if (selectedOrder){
    setMsg(false);
    setOrderCurrentPage(0);
   
    return filteredOrderData;
  }
  return data;
}


var filteredList = useMemo(getFilteredList, [selectedCategory, selectedOrder,item, data, filteredData, filteredOrderData]);
useEffect(()=>{
if(leng == 0 ){
  setMsg(true);
}
else{
  setMsg(false);
}
}, [leng])


console.log(msg);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>

<div className='category-filter m-2 '>
          <Grid
            container
            spacing={3}
          >
              
            <Grid
              item
              md={3} 
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="en_name"
                name="en_name"
                onChange={handleCategoryChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع المدن</option>
                {city.slice(0).map(option => (
                  <option
                    key={option.id}
                    value={option.en_name}
                  >
                    {option.en_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="status"
                name="status"
                onChange={handleOrderChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع الطلبات</option>
                <option  value={'canceled'}>الطلبات الملغاة</option>
                <option  value={'accepted'}>الطلبات قيدالتنفيذ</option>
                <option  value={'new_ride_requested'}>في انتظار بدء الرحلة</option>
                <option  value={'new_ride_requested'}>في انتظار موافقة السائق</option>
                <option  value={'completed'}>الطلبات المكتملة</option>
              </TextField>
            </Grid>
            </Grid>
            </div>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
                  <TableCell align='right'>رقم الطلب</TableCell>
                  <TableCell align='right'>تاريخ الطلب</TableCell>
                  <TableCell align='right'>الخدمة</TableCell>
                  <TableCell align='right'>مدينة الطلب</TableCell>
                  <TableCell align='right'>طالب الخدمة</TableCell>
                  <TableCell align='right'>رقم الهاتف</TableCell>
                  <TableCell align='right'>السائق</TableCell>
                  <TableCell align='right'>رقم الهاتف</TableCell>
                  <TableCell align='right'>المبلغ</TableCell>
                  <TableCell align='right'>حالة الدفع</TableCell>
                  <TableCell align='right'>حالة الطلب</TableCell>
                  <TableCell align='right'> سبب الإلغاء</TableCell>
                  <TableCell align='right'>عرض الفاتورة</TableCell>
        
                </TableRow>
              </TableHead>
              {isLoading ? <h6 align='left' className='text-secondary content-align-end m-3'>جاري تحميل الطلبات ...</h6>:
              <>
              <TableBody>
                {filteredList.slice(0).map(post => (

                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >     
  
                     <TableCell align='right'>{post.id}</TableCell>
                    <TableCell align='right' dir='ltr'>{moment(convertUtcToLocal(post.datetime).toString()).format('DD-MM-YYYY hh:mm A')}</TableCell>
                    <TableCell align='right'>{post.service_name}</TableCell>
                    <TableCell align='right'>{post.request_city}</TableCell>
                    <TableCell align='right'>{post.customer_name}</TableCell>
                    <TableCell align='right' dir='ltr'>{post.customer_phone}</TableCell>
                    <TableCell align='right'>{post.driver_name}</TableCell>
                    <TableCell align='right' dir='ltr'>{post.driver_phone}</TableCell>
                    <TableCell align='right'>{parseInt(post.price)} <span>ر.س.</span></TableCell>
                    {post.paid == 1 ?
                    <TableCell align='right'>تم الدفع</TableCell>
                    : post.paid == 2 ? 
                    <TableCell align='right'>تم الاسترداد</TableCell>
                    :
                    <TableCell align='right'>لم يتم الدفع</TableCell>
                    }
                    {post.status == 'canceled' ? 
                    <TableCell align='right'>ملغي</TableCell>
                    : post.status == 'completed' ? 
                    <TableCell align='right'>مكتمل</TableCell>
                  : post.status == 'accepted' ||  post.status == 'in_progress'? 
                  <TableCell align='right'>قيد الاجراء</TableCell>
                  : 
                <TableCell align='right'>طلب جديد</TableCell>
                  }
                  <TableCell align='right'>{post.cancel_reason}</TableCell>
                    <TableCell align='right'>
                    <Link target="_blank" to={`/invoice/${post.id}`}><FaFileInvoiceDollar size="20px" /></Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              
              </>
 }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
      <div className='pagination' dir='ltr'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ?  filteredPageCount : selectedOrder ? pageOrderCounts : pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={selectedCategory ? hnadleFilteredPageClick : selectedOrder ? hnadleFilteredOrderPageClick : handlePageClick}
           
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>

  );
}

export default OrdersTable;
