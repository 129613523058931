import axios from "axios"; 
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import { Grid } from "@material-ui/core";

const AddRole = () =>{
    const [role,setRole] = useState('');
    const [pages,setPages] = useState('');
    const [operations, setOperations] = useState([]);

    var accessTokenObj = (localStorage.getItem("token"));
    const passedToken = accessTokenObj;

    const BASE_URL = process.env.REACT_APP_API_URL;
    const PAGE_URL = `${BASE_URL}/pages`;
    const ADD_ROLE_URL = `${BASE_URL}/addRole`;
    const [loader, setLoader] = useState(false);

    
    let tempOp = [...operations];
    const handleChange = (e) => 
    {
        tempOp = [...operations, e.target.value];
        setOperations(tempOp);
    };

    useEffect(()=>{ fetchPages();},[]);

    const fetchPages = async() =>{

        await axios.get(PAGE_URL,{ headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      }).
        then(({data}) =>
        {
            console.log('dddddddd',data);
            setPages(data);
        });
    }


    const handleSubmission =  (e) => {
      e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("role",role);
        formData.append("operations",operations);
         fetch(ADD_ROLE_URL,{
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          },
        
          method: "POST",
          body: formData
        })
        .then((res)=>{
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            
            }
            else if(res.status == 200){
              setLoader(false);
               localStorage.setItem('toastMessage', 'تم إضافة الدور ');
               window.location.replace('/roles');
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }
            else if(res.status == 500){
              toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
              setLoader(false);
            }
            else if(res.status == 404){
              toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')
              setLoader(false);
            }
            else{
              toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
              setLoader(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ في الإدخال حاول مرة أخرى ');
          setLoader(false);
         
        });
      };

    

    return(
        <div className="Container" dir="rtl">
            <div className="card text-right">
                <div className="card-header">إنشاء الصلاحيات</div>
                <div className="card-body">
                    <form onSubmit={handleSubmission}>
                        <div className="mb-3">
                            <label className="form-label">دور المستخدم : </label>
                            <input type="text" className="form-control" value={role} onChange={(e)=>setRole(e.target.value)}/>
                        </div> 
                        <Grid
            container
            spacing={1}
          >
                        {pages.length > 0 && (
                                pages.map(page=>(<div>
                            <label className="form-label"><b>{page.page} </b> </label><br />
                            {page.operations.map((op)=>  (<div key={op.id}> 
                                <input className="form-check-input" type="checkbox"
                                 value={op.id} 
                                 onChange={(e) => handleChange(e)} />  
                                &nbsp;  {op.operation} </div> )     
                        )}
                        </div>
                        ))
                        )}

                        </Grid>
                        

             <button className='btn btn-warning px-3 text-white m-4'  >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> حفظ</>
        }
        </button>

                    </form>
                </div>       
            </div>
        </div>
    );
}


export default AddRole;
