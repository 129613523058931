import React, { useContext, useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import {  Profile2} from '../../../../EntryFile/imagePath';
import {FaTelegramPlane} from 'react-icons/fa';
import { ShopContext } from "context/shop-context";
import moment from "moment";
import axios from "axios";
import { TextField } from "@material-ui/core";





const Chats = () => {
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;
  const BASE_URL = process.env.REACT_APP_API_URL;
  let accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
  
  const [msg, setMsg] = useState("");

  const {conversations, chatMessages, getChatMessages, getSenderData, chatSender,
     message, setMessageAsRead, convertUtcToLocal, setReadMsg,} = useContext(ShopContext);

useEffect(()=>{
  getChatMessages(chatSender.id);
  scrollToDown();
},[message]);

  const handelSendMessage = async (e) => {
    e.preventDefault();
     const post = {
       message: msg, 
       receiver_id:chatSender.user_id,
      type: 1 }
     try {
           await axios.post(`${BASE_URL}/chats/send`, post, { 
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }})
  
        getChatMessages(chatSender.id);
        setMsg("");
        scrollToDown();
        } catch (e) {
          console.log(e);
          setMsg("");
      }
    }
      
    const divRef = useRef(null);

  const scrollToDown = () =>{
    if (!divRef.current) return;
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="col-lg-12">
          <div className="row chat-window">
            {/* Chat User List */}
            <div className="col-lg-5 col-xl-4 chat-cont-left">
              <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                <div className="card-header chat-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="search_btn">
                        <i className="fas fa-search" />
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="بحث"
                      className="form-control search-chat rounded-pill"
                    />
                  </div>
                </div>
                <div className="card-body contacts_body chat-users-list chat-scroll">
                {conversations.map(conversation=>(
                  <><Link onClick={(e) => {getChatMessages(conversation.id); getSenderData(e, conversation.id); setMessageAsRead(e, conversation.message_id); scrollToDown()}}
                  className="media d-flex active">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="px-1">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="rounded-circle user_img"
                          width="40" />
                      </div>
                    </div>

                    <div className="media-body flex-grow-1">
                      <div>
                      {conversation.sender_name == localStorage.getItem('name') ?
                        <div className="user-name">{conversation.receiver_name} </div>: 
                        <div className="user-name">{conversation.sender_name} </div>
                      }
                        <div className="user-last-chat">
                              {conversation.message}</div>
                      </div>
                      <div>
                        <div className="last-chat-time">{moment(convertUtcToLocal(conversation.send_at).toString()).format('hh:mm A')} </div>
                        { (conversation.status === 'unread') && (conversation.message_type == 0)
                        ? <>
                        <div className="badge badge-success badge-pill">1</div>
                        {setReadMsg(true)}</>
                        : <div className="badge badge-success badge-pill"></div>
                        }
                      </div>
                    </div>

                  </Link>
                    
                    </>
               
                 )
                 )}
                 </div>
              </div>
              
            </div>
            
            {/* Chat User List */}
            {/* Chat Content */}
            {!chatSender.id ? <>
              <div className="col-lg-7 col-xl-8 chat-cont-right">
              <div className="card mb-0">
                <div className="img text-center m-5">
                {/* <img src="https://img.freepik.com/free-icon/chat_318-615247.jpg" width='50%'/> */}
                <img src="https://www.topworkgroep.nl/wp-content/uploads/2021/12/contact2.gif" width='50%'/>
                </div>
                <div className="img text-center m-5">
                <h4>أضغط على  المحادثة لعرض الرسائل</h4>
                </div>
               
                </div>
                </div></> :
            <div className="col-lg-7 col-xl-8 chat-cont-right">
              {/* Chat History */}
              <div className="card mb-0">
                <div className="card-header msg_head">
                  <div className="d-flex bd-highlight">
                    <Link
                      id="back_user_list"
                      to="javascript:void(0)"
                      className="back-user-list"
                    >
                      <i className="fas fa-chevron-left" />
                    </Link>
                    <div className="img_cont">
                      
                      <img
                        className="rounded-circle user_img"
                        src={Profile2}
                        alt=""
                      />
                    </div>
                             <div className="user_info">
                             <span>
                               <strong id="receiver_name">{chatSender.username}</strong>
                             </span>
                             {chatSender.type == 'App\\Models\\Driver'?
                              <p className="mb-0">سائق</p>
                              :chatSender.type == 'App\\Models\\Agent'?
                              <p className="mb-0">وكيل</p>
                              :chatSender.type == 'App\\Models\\Company'?
                              <p className="mb-0">شركة</p>
                              :chatSender.type == 'App\\Models\\Customer'?
                              <p className="mb-0">طالب خدمة</p>
                              :
                              <p className="mb-0"></p>
                              }
                           
                           </div>
             
                  </div>
                </div>
                <div className="card-body msg_card_body chat-scroll">
                  <ul className="list-unstyled">
                  {chatMessages.map(chat=>(
                    <>
                   {chat.message_type == 1?
                    <li className="media sent d-flex">
                    <div className="avatar flex-shrink-0">
                      <div className="px-1">
                        <img
                          src={Profile2}
                          alt="User Image"
                         className="rounded-circle user_img"
                         width="40"
                        />
                      </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box text-end">
                          <div>
                            <p>{chat.message}</p>
                            
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>{moment(convertUtcToLocal(chat.send_at).toString()).format('hh:mm A')}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div ref={divRef} />
                    </li>
                    
                     :
                     <li className="media received d-flex">
                     <div className="avatar flex-shrink-0">
                       <div className="px-1">
                         <img
                           src={Profile2}
                           alt="User Image"
                          className="rounded-circle user_img"
                          width="40"
                         />
                       </div>
                       </div>
                       <div className="media-body flex-grow-1">
                         <div className="msg-box text-end">
                           <div>
                           {(!localhost_url + chat.attach) && (localhost_url + chat.attach != "null") ?
                            
                            <img src={localhost_url + chat.attach} width="220" height="160"/>
                            : 
                            <p>{chat.message}</p>
                            }
                             <ul className="chat-msg-info">
                               <li>
                                 <div className="chat-time">
                                   <span>{moment(chat.send_at.toLocaleString()).format('hh:mm A')} </span>
                                 </div>
                               </li>
                             </ul>
                           </div>
                         </div>
                       </div>
                       <div ref={divRef} />
                     </li>
                           
                    }
                     </>
                            ))}
                  </ul>
                </div>
                <form  onSubmit={handelSendMessage}>
                <div className="card-footer">
                  <div className="input-group" dir="ltr">
                   
                         <TextField dir="rtl"
                  className="form-control type_msg mh-auto empty_check  "
                  placeholder="أكتب رسالتك..."
                  type="text" 
                  name="en_name"
                  onChange={(e)=>setMsg(e.target.value)}
                  value={msg}
                  variant="outlined"
                  />
                  
                    <button className="btn btn-success btn_send">

                      < FaTelegramPlane size="24px" color="#fff"/>
                    </button>
                   
     
                  </div>
                </div>
                </form>
              </div>
            </div>
}
            {/* Chat Content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
