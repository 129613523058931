import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddAppSetting = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const SERVICES_URL = `${BASE_URL}/appSettings`; 
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const [data, setData] = useState({
      order_auto_decline_time : 0,
      service_order_distance : 0,
      order_attempt : 0,
      otp_resend_time : 0,
      order_cancel_time : 0,
      payment_key : '',
      support_number : 0,
      android_url : '',
      ios_url : '',
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("order_auto_decline_time", data.order_auto_decline_time);
        formData.append("service_order_distance", data.service_order_distance);
        formData.append("order_attempt", data.order_attempt);
        formData.append("otp_resend_time", data.otp_resend_time);
        formData.append("order_cancel_time", data.order_cancel_time);
        formData.append("payment_key", data.payment_key);
        formData.append("support_number", data.support_number);
        formData.append("android_url", data.android_url);
        formData.append("ios_url", data.ios_url);
        await fetch (SERVICES_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
              toast.success('تم إضافة الإعدادات');
              setLoader(false);
                history.push('/app-setting');
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="إضافة  الإعدادات"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="التحويل التلقائي للطلب"
                margin="dense"
                type='number'
                name="order_auto_decline_time"
                id="order_auto_decline_time"
                onChange={(e)=> handle(e)} 
                value={data.order_auto_decline_time}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="نطاق الطلب"
                margin="dense"
                type='number'
                name="service_order_distance"
                id="service_order_distance"
                onChange={(e)=> handle(e)} 
                value={data.service_order_distance}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                label="محاولات الطلب"
                margin="dense"
                name="order_attempt"
                id="order_attempt"
                onChange={(e)=> handle(e)} 
                value={data.order_attempt}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                label="مدة إعادة ارسال OTP"
                margin="dense"
                name="otp_resend_time"
                id="otp_resend_time"
                onChange={(e)=> handle(e)} 
                value={data.otp_resend_time}
                required
                variant="outlined"
              />
            </Grid>

            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                label="الإلغاء التلقائي للطلب"
                margin="dense"
                name="order_cancel_time"
                id="order_cancel_time"
                onChange={(e)=> handle(e)} 
                value={data.order_cancel_time}
                required
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='text'
                label="مفتاح ربط الدفع"
                margin="dense"
                name="payment_key"
                id="payment_key"
                onChange={(e)=> handle(e)} 
                value={data.payment_key}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='text'
                label="رقم الدعم الفني"
                margin="dense"
                name="support_number"
                id="support_number"
                onChange={(e)=> handle(e)} 
                value={data.support_number}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='text'
                label="رابط قوقل استور"
                margin="dense"
                name="android_url"
                id="android_url"
                onChange={(e)=> handle(e)} 
                value={data.android_url}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='text'
                label="رابط ابل استور"
                margin="dense"
                name="ios_url"
                id="ios_url"
                onChange={(e)=> handle(e)} 
                value={data.ios_url}
                required
                variant="outlined"
              />
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-warning text-white px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إضافة </>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddAppSetting.propTypes = {
  className: PropTypes.string
};

export default AddAppSetting;
