import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import SettingsIcon from '@material-ui/icons/Settings';
import { BiCategory } from "react-icons/bi";
import { BsSubtract } from "react-icons/bs";
import { RiListUnordered } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import { FaFileContract } from "react-icons/fa";
import { BsFillTruckFrontFill } from "react-icons/bs";
import { FaMagento } from "react-icons/fa";
import { MdHomeRepairService } from "react-icons/md";
import { MdQuestionAnswer } from "react-icons/md";
import { FaCity } from "react-icons/fa";
import { MdOutlineScreenshot } from "react-icons/md";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { IoMdImages } from "react-icons/io";
import { MdOutlinePolicy } from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { TbMessage2Code } from "react-icons/tb";
import { MdRequestPage } from "react-icons/md";
import { MdFireTruck } from "react-icons/md";
import { SiZendframework } from "react-icons/si";
import { BsTruckFrontFill } from "react-icons/bs";
import { BiArchiveIn } from "react-icons/bi";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { BiCommentError } from "react-icons/bi";
import { AiFillSetting } from "react-icons/ai";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { AiFillQuestionCircle } from "react-icons/ai";
import { TbLockAccess } from "react-icons/tb";


import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'لوحة التحكم',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'الأقسام والتصنيفات',
      href: '/category',
      icon: <BiCategory size='30px' />
    },
    {
      title: 'الأقسام الفرعية',
      href: '/sub-category',
      icon: <BsSubtract size='22px' />
    },
    {
      title: 'الخدمات',
      href: '/services',
      icon: <MdHomeRepairService size='30px' />
    },
    {
      title: 'الطلبات',
      href: '/orders', 
      icon: <RiListUnordered size='30px' />
    },
    {
      title: 'العملاء',
      href: '/customers',
      icon: <BsFillPeopleFill size='28px' />
    },
    {
      title: 'السائقين',
      href: '/drivers', 
      icon: <BsFillTruckFrontFill size='20px' />
    },
    {
      title: 'الوكلاء',
      href: '/agents',
      icon: <FaMagento size='22px' />
    },
    {
      title: 'الشركات والمؤسسات',
      href: '/companies',
      icon: <CgOrganisation size='22px' />
    },
    {
      title: 'عقود ومشاريع',
      href: '/projects', 
      icon: <FaFileContract size='20px' />
    },
    {
      title: 'طلبات العقود',
      href: '/contracts', 
      icon: <MdRequestPage size='28px' />
    },
    {
      title: 'خدمات المعدات',
      href: '/heavy-machine', 
      icon: <MdFireTruck size='28px' />
    },

    {
      title: 'موديل المركبة',
      href: '/models', 
      icon: <BsTruckFrontFill size='20px' />
    },

    {
      title: 'حجم المعدات',
      href: '/heavy-machine-size', 
      icon: <SiZendframework size='28px' />
    },
    {
      title: 'أسئلة السطحة',
      href: '/truck-question', 
      icon: <AiFillQuestionCircle size='28px' />
    },

    {
      title: 'أسباب الغاء الطلب',
      href: '/cancel-reason', 
      icon: <MdFormatListBulletedAdd size='28px' />
    },
   
    
    // {
    //   title: 'الخريطة',
    //   href: '/map', 
    //   icon: <MdFormatListBulletedAdd size='24px' /> 
    // },

    {
      title: 'الدردشة',
      href: '/chats', 
      icon: <BsFillChatLeftTextFill size='20px' /> 
    },
    {
      title: 'المدن',
      href: '/cities',
      icon: <FaCity size='20px' /> 
    },
    {
      title: 'الإعلانات',
      href: '/promotions',
      icon: <IoMdImages size='24px' /> 
    },
    
   
    {
      title: 'المستخدمين',
      href: '/users',
      icon: <BsFillPeopleFill size='26px' />
    },
    {
      title: 'تعديل كلمة المرور',
      href: '/settings',
      icon: <AiFillSetting size='28px'/>
    },
    {
      title: 'الصلاحيات',
      href: '/roles',
      icon: <TbLockAccess size='28px'/>
    },
    
    
    {
      title: 'سياسة الخصوصية',
      href: '/policies',
      icon: <MdOutlinePolicy size="24px"/>
    },
    {
      title: 'رسائل التحقق',
      href: '/opt-messages',
      icon: <TbMessage2Code size="24px"/>
    },
    {
      title: 'الخصومات',
      href: '/discount',
      icon: <MdDiscount size="24px"/>
    },
    {
      title: 'الشاشات الترحيبية ',
      href: '/welcomes',
      icon: <MdOutlineScreenshot size='30px' />
    },
    {
      title: 'الاسئلة الشائعة',
      href: '/quesions',
      icon: <MdQuestionAnswer size='30px' />
    },
    {
      title: 'الارشيف',
      href: '/archived-user',
      icon: <BiArchiveIn size='22px' />
    },

    {
      title: 'إعدادات التطبيق',
      href: '/app-setting', 
      icon: <AiFillSetting size='28px' />
    },
    
    {
      title: 'سجل الأخطاء',
      href: '/error-log', 
      icon: <BiCommentError size='24px' />
    },

 
  ];

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
