import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditWelcome = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams(); 
    const [img, setImg] = useState('');

    const BASE_URL = process.env.REACT_APP_API_URL;
    const WELCOME_URL = `${BASE_URL}/welcomes/${id}`; 
    
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
      const res = await axios.get(WELCOME_URL,{ headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    });
      setData(res.data);
      setImg(res.data.image)
    };

    const [data, setData] = useState({ 
      ar_title : "",
      ar_text : "",
      en_title : "",
      en_text : "",
      ordering : null,
      is_active : 1,
    });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

    const handleSubmission = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", selectedFile || img );
      formData.append("ar_title", data.ar_title);
      formData.append("ar_text", data.ar_text);
      formData.append("en_title", data.en_title);
      formData.append("en_text", data.en_text);
      formData.append("is_active", data.is_active);
      formData.append("ordering", data.ordering);
      await fetch(WELCOME_URL, {
        method: "POST",
        body: formData,
        headers:{
          'Authorization': ` Bearer ${passedToken}` 
        }
      })
      .then((res)=>{
        setLoader(false);
        console.log(res);
        if(!res.status){
          toast.error('فشل الاتصال بالخادم الرجاء المحاولة مرة أخرى');
        }

        else if(res.status == 500){
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        }
        else if(res.status == 404){
          setLoader(false);
          toast.error('تأكد من الاتصال و المحاولة مرة أخرى');
        }
        else if(res.status == 401){
          toast.error('وصول غير مصرح به');
          setLoader(false);
        }
        else if(res.status == 201){
          setLoader(false);
            localStorage.setItem('toastMessage', 'تم التعديل');
            window.location.replace('/welcomes');
        }
        else{
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        } 
        loadList();
      })
      .catch(()=>{
        toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        setLoader(false);
      });
    };

    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
      console.log(newdata)
    }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
        title="تعديل الإعلان"
        />
        <Divider />
        <CardContent>
        <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="العنوان باللغة العربية"
                label="العنوان باللغة العربية"
                margin="dense"
                name="ar_title"
                id="ar_title"
                onChange={(e)=> handle(e)} 
                value={data.ar_title}
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="النص باللغة العربية"
                label="النص باللغة العربية"
                margin="dense"
                name="ar_text"
                id="ar_text"
                onChange={(e)=> handle(e)} 
                value={data.ar_text}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="العنوان باللغة الإنجليزية"
                label="العنوان باللغة الإنجليزية"
                margin="dense"
                name="en_title"
                id="en_title"
                onChange={(e)=> handle(e)} 
                value={data.en_title}
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="النص باللغة الإنجليزية"
                label="النص باللغة الإنجليزية"
                margin="dense"
                name="en_text"
                id="en_text"
                onChange={(e)=> handle(e)} 
                value={data.en_text}
                required
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="حالة المحتوى"
                margin="dense"
                id="is_active"
                name="is_active"
                onChange={(e)=> handle(e)} 
                value={data.is_active}
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
             <option value={1}>مفعل </option>
             <option value={0}>غير مفعل </option>
              </TextField>

            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                // helperText="ترتيب الإعلان"
                label="الترتيب"
                margin="dense"
                name="ordering"
                id="ordering"
                onChange={(e)=> handle(e)} 
                value={data.ordering}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
               {/* <label for="inputImage">أضف صورة الإعلان </label> */}
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>

<button className='btn btn-warning px-3  m-4 text-white' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> تحديث البيانات</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditWelcome.propTypes = {
  className: PropTypes.string
};

export default EditWelcome;
