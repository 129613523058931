import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function ErrorLogTable(props) {

    const toastMessage = localStorage.getItem('toastMessage');
  
    useEffect(() => {
      toast.success(toastMessage)
      localStorage.removeItem('toastMessage');
    }, []);
  
  const BASE_URL = process.env.REACT_APP_API_URL;
  const SERVICES_URL = `${BASE_URL}/errorLog`;

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [msg , setMsg] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [leng , setLeng] = useState(0);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);

  
  const [selectedCategory, setSelectedCategory] = useState();


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);
  
 
  const fetchData =  async (pageNumber) => {
    await axios.get(`${SERVICES_URL}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${SERVICES_URL}/otpMessages/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then(  (response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    setLeng(response.data.data.length);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};

  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };
  
const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
}

  const { className, ...rest } = props;

  const classes = useStyles();

  const [item, setItem] = useState([]);
  useEffect(()=>{
    setItem(data);
   
  },[data])


function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
}

function getFilteredList() {
  if (!selectedCategory) {
    setMsg(false);
    return data;
   
  
  }

  setFilterCurrentPage(0);

  return filteredData;
}
var filteredList = useMemo(getFilteredList, [selectedCategory, data, filteredData, item]);

useEffect(()=>{
if(leng == 0 ){
  setMsg(true);
}
else{
  setMsg(false);
}
}, [leng])
let x= handleCategoryChange;
x= 0;
console.log(msg, x);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
                  <TableCell align='right'> التسلسل</TableCell>
                  <TableCell align='right'> الخطأ</TableCell>
                  <TableCell align='right'>التاريخ</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <h6 align='left' className='text-secondary content-align-end m-3'>جاري تحميل المنتجات ...</h6>:
              <>
             
              <TableBody>
                {filteredList.slice(0).map(post => (
               
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >     
      
                    <TableCell align='right'>{post.id}</TableCell>
                    <TableCell align='right'>{post.error_log}</TableCell>
                    <TableCell align='right'>{moment(post.expire_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              </>
 }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions} >
        <div className='pagination' dir='ltr'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ? filteredPageCount : pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={selectedCategory ? hnadleFilteredPageClick : handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>

  );
}

export default ErrorLogTable;
