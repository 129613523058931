import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { MdLibraryAddCheck } from "react-icons/md";
import { RiInboxArchiveLine } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdNotificationAdd } from "react-icons/md";
import { MdTextsms } from "react-icons/md";

const useStyles = makeStyles(() => ({
  root: {}
}));

function Password () {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const {userArchive, agentActivate, agentInActivate, aprroveAgentChange, sendOtpMessage, sendNotification} = useContext(ShopContext);
  const {id} = useParams(); 
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [driverPhone, setDriverPhone] = useState("");
  useEffect(() => {
    axios.get(`${BASE_URL}/agents/agentPhone/${id}`,  { 
      headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then(res => {
      setDriverPhone(res.data.phone);
    }).catch(err => {
      console.log(err)
    })
  }, []);

  return (

        <Grid
            container
            spacing={2}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="primary"
           variant="contained"
          fullWidth="600"
          onClick={(e)=> aprroveAgentChange(e, id)}> 
          <div className='container'>
          <div className='row'>
            <div className='col-10 text-end'>
            الموافقة على التعديلات 
            </div>
            <div className='col-2 text-end'>
            <MdLibraryAddCheck color='green' size="22px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>

          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button component={Link} to={`/agent-driver-list/${id}`}
           color="primary"
           variant="contained"
          fullWidth="600"
         > 
          <div className='container'>
          <div className='row'>
            <div className='col-10 text-end text-decoration-none text-white'>
            السائقين
            </div>
            <div className='col-2 text-end'>
            <BsFillPeopleFill color='green' size="22px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>

          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button component={Link} to={`/agent-order-list/${id}`}
           color="primary"
           variant="contained"
          fullWidth="600"
         > 
          <div className='container'>
          <div className='row'>
            <div className='col-10 text-end text-decoration-none text-white'>
            الطلبات
            </div>
            <div className='col-2 text-end'>
            <FaClipboardList color='green' size="22px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>

          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="primary"
           variant="contained"
           fullWidth="600"
           onClick={(e)=> sendOtpMessage(e, driverPhone)}
          >
                 <div className='container'>
             <div className='row d-flex align-content-end'>
            <div className='col-10 text-end'>
            إرسال رسالة قصيرة
             </div>
            <div className='col-2 text-end  '>
            <MdTextsms color='green' size="20px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>
          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="primary"
           variant="contained"
           fullWidth="600"
           onClick={(e)=> sendNotification(e, id)}
          >
           <div className='container'>
             <div className='row d-flex align-content-end'>
            <div className='col-10 text-end'>
            إرسال إشعار
             </div>
            <div className='col-2 text-end  '>
            <MdNotificationAdd color='green' size="24px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>
               <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="primary"
           variant="contained"
           fullWidth="600"
           onClick={(e)=> agentActivate(e, id)}
          >
             <div className='container'>
             <div className='row d-flex align-content-end'>
            <div className='col-10 text-end'>
            تفعيل الحساب
             </div>
            <div className='col-2 text-end  '>
            <FaUserCheck color='green' size="22px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>
          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="primary"
           variant="contained"
           fullWidth="600"
           onClick={(e)=> agentInActivate(e, id)}
          >
           <div className='container'>
             <div className='row d-flex align-content-end'>
            <div className='col-10 text-end'>
            إلغاء تفعيل الحساب 
             </div>
            <div className='col-2 text-end  '>
            <MdCancel color='red' size="24px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>

          <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
        <Button
            color="secondary"
           variant="contained"
           fullWidth="600"
           onClick={(e)=> userArchive(e, id)}
          >
              <div className='container'>
             <div className='row d-flex align-content-end'>
            <div className='col-10 text-end'>
              أرشفة  الحساب
             </div>
            <div className='col-2 text-end  '>
            <RiInboxArchiveLine color='white' size="22px"/>
            </div>
          </div>
          </div>
          </Button>
          </Grid>
          </Grid>
  );
} 

export default Password;
