import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { ShopContext } from 'context/shop-context';
import { IoMdNotifications } from "react-icons/io";
import { MdNotificationAdd } from "react-icons/md";


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const {userLoggedOut, readMsg} = useContext(ShopContext);

  const classes = useStyles();

  const [notifications] = useState([]);
  
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            src="/images/logos/logo-12.png"
          width="8%"/>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
      {readMsg == true ?
      <>
        
          <IconButton color="inherit">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot">
              <RouterLink to="/chats">
            <MdNotificationAdd color='#fff' size='28px'/>
            </RouterLink>
          </Badge>
        </IconButton>

        </>
      :
      <IconButton color="inherit" >
      <Badge
        badgeContent={notifications.length}
        color="primary"
        variant="dot"
      >
          <RouterLink to="/chats">
        <IoMdNotifications color='#fff' size='28px'/>
        </RouterLink>
      </Badge>
    </IconButton>
      }
      
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={userLoggedOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
