import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditPolicy = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const EDIT_POLICY_URL = `${BASE_URL}/policies/${id}`;
    
    var accessTokenObj = (localStorage.getItem("token"));
    const passedToken = accessTokenObj;
  

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadList();
      }, []);
      const loadList = async () => {
        const res = await axios.get(EDIT_POLICY_URL,{ headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      });
        setData(res.data)
      };

      const [data, setData] = useState({
        ar_text: "",
        en_text: ""
      });

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();

        formData.append("ar_text", data.ar_text);
        formData.append("en_text", data.en_text);
        await fetch(EDIT_POLICY_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}` 
          }
        })
        .then((res)=>{
          console.log(res);
          if(!res.status){
            toast.error('خطأ في الاتصال بالخادم')
            setLoader(false);
          }

          else if(res.status == 500){
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('تأكد من الاتصال بالانترنت و المحاولة مرة أخرى')
            setLoader(false);
          }
          else if(res.status == 201){
            setLoader(false);
            localStorage.setItem('toastMessage', 'تم تعديل سياسة الخصوصية ');
            window.location.replace('/policies');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
            setLoader(false);
          } 
          loadList();
        })
        .catch(()=>{
          setLoader(false);
          toast.error('حدث خطا الرجاء المحاولة مرة أخرى');
        });
      };
      
      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>

      

      <form onSubmit={handleSubmission}>
        <CardHeader
          // subheader="The information can be edited"
          title="تعديل سياسة الخصوصية"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}>
              
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="سياسة الخصوصية باللغة العربية"
                margin="dense"
                id="ar_text"
                type="text"
                name="ar_text"
                onChange={(e)=> handle(e)}
                value={data.ar_text}
                required
                variant="outlined"
              />
            </Grid>


            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="سياسة الخصوصية باللغة الإنجليزية"
                margin="dense"
                id="en_text" 
                type="text" 
                name="en_text"
                onChange={(e)=> handle(e)} 
                value={data.en_text}
                required
                variant="outlined"
                
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>


<button className='btn btn-warning text-white px-3  m-4'  >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>تعديل البيانات </>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditPolicy.propTypes = {
  className: PropTypes.string
};

export default EditPolicy;
