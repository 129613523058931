import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { HiUserCircle } from 'react-icons/hi';
import { MdPhoneIphone } from 'react-icons/md';
import { AiFillCreditCard } from 'react-icons/ai';
import { BsEyeFill } from "react-icons/bs";
// import { ImBin } from "react-icons/im";
import { TbPhotoEdit } from "react-icons/tb";
import { GiModernCity } from "react-icons/gi";
import { IoMdBus } from "react-icons/io";
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 


import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
  InputAdornment,
  // IconButton,

} from '@material-ui/core';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

const {city} = useContext(ShopContext);

    const {id} = useParams(); 
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFile1, setSelectedFile1] = useState();

    // const handleRemove = () =>{
    //   setSelectedFile(0);
    // }

    const BASE_URL = process.env.REACT_APP_API_URL;
    const DRIVERS_URL = `${BASE_URL}/vehicles/${id}`;  
    
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
      const res = await axios.get(DRIVERS_URL,{
         headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    });
      setData(res.data);
    };
  

    const [data, setData] = useState({
      name : "",
      phone : "",
      bank_account : 0,
      address_id : null,
      isDriverAcceptRequest : null,
      
    });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

    const handleSubmission = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("front_photo", selectedFile);
      formData.append("side_photo", selectedFile1);
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("bank_account", data.bank_account);
      formData.append("address_id", data.address_id);
      formData.append("isDriverAcceptRequest", data. isDriverAcceptRequest);
      
      
      await fetch(DRIVERS_URL, {
        headers:{
       'Authorization': ` Bearer ${passedToken}`}
       ,
        method: "POST",
        body: formData,

      })
      .then((res)=>{
        setLoader(false);
        console.log(res);
        if(!res.status){
          toast.error('فشل الاتصال بالخادم الرجاء المحاولة مرة أخرى');
        }
        else if(res.status == 201){
          setLoader(false);
             toast.success('تم تعديل البيانات');
        }

        else if(res.status == 500){
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        }
        else if(res.status == 404){
          setLoader(false);
          toast.error('تأكد من الاتصال و المحاولة مرة أخرى');
        }
      
        else{
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        } 
        loadList();
      })
      .catch(()=>{
        toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        setLoader(false);
      });
    };

    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
    }

    const localhost_url = process.env.REACT_APP_IMAGE_URL;
    const defaultImage = "https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png";

  return (
    <Card 
      {...rest}
      className={clsx(classes.root, className)}
    >
       <form onSubmit={handleSubmission}>
        <CardHeader
          title="بيانات السائق"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
            <div className='d-flex justify-content-center'>
              <img class="avatar avatar-48 bg-light rounded-circle text-white p-2 justify-content-center"
               src={(!localhost_url + data.photo) ?  (localhost_url + data.photo) : defaultImage} width="360px" height="360" />
              </div> 
              <Grid
              item
              md={12}
              xs={12}
            >
              <TextField InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <HiUserCircle size="30px"/>
                </InputAdornment>
            )}}
                fullWidth
                label='اسم السائق'
                margin="dense"
                name="name"
                id="name"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.name}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <MdPhoneIphone size="30px"/>
                </InputAdornment>
            )}}
                fullWidth
                label='رقم الهاتف'
                margin="dense"
                name="phone"
                id="phone"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.phone}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12} 
            >
              <TextField 
              InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <AiFillCreditCard size="24px"/>
                </InputAdornment>
            )}}
                fullWidth
                label='رقم الحساب البنكي'
                margin="dense"
                name="bank_account"
                id="bank_account"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.bank_account}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12} >
              <TextField
                fullWidth
                InputProps={{startAdornment: (
                  <InputAdornment position="start">
                     <GiModernCity size="26px"/>
                  </InputAdornment>
              )}}
                margin="dense"
                label='المدينة'
                id="address_id"
                name="address_id"
               onChange={(e)=> handle(e)} value={data.address_id}
                required
                select
                SelectProps={{ IconComponent: () => null, native: true  }}
                variant="outlined"
              >
                 {city.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>   
            </Grid>

                  <Grid
              item
              md={12}
              xs={12} >
              <TextField
              InputProps={{startAdornment: (
                <InputAdornment position="start">
                 
                  <IoMdBus size="26px"/>
                </InputAdornment>
            )}}
                fullWidth
                margin="dense"
                label='قبول الطلبات'
                id="isDriverAcceptRequest"
                name="isDriverAcceptRequest"
                onChange={(e)=> handle(e)} value={data.isDriverAcceptRequest}
                required
                select
                variant="outlined"
                SelectProps={{ IconComponent: () => null, native: true  }}>
               
                  <option value={1}>مفعلة</option> 
                  <option value={0}>غير مفعلة</option> 

              </TextField>
            
            </Grid>

          
           <div className='row my-2'>
           <div className='col-9'>
            <Typography
        
                gutterBottom
                variant="h6"
              >
                صورة المركبة الامامية
              </Typography>
              </div>
              <div className='col-1'>
              <div className='change-image'>
              <div>
                 <label 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                 htmlFor="front_photo">
                   <input name="front_photo" type="file" id="front_photo" hidden />
                   <TbPhotoEdit size="26px" />
                   </label>
                   </div> 
                </div>
              </div>
              {/* <div className='col-1'>
                <div className='change-image'>
                <IconButton size='small' onClick={handleRemove}>
                <ImBin size="22px" color='red'/>
                </IconButton>
                </div>
              </div> */}
              <div className='col-1'>
                <div className='change-image'>
                <Link to={`/front-image/${id}`} className='' >
                  <BsEyeFill size="26px" color='green'/></Link>
                </div>
                </div>
             
              </div>
              <div className='row my-2'>
           <div className='col-9'>
            <Typography
        
                gutterBottom
                variant="h6"
              >
                صورة المركبة الجانبية
              </Typography>
              </div>
              <div className='col-1'>
              <div className='change-image'>
              <div>
                 <label 
                  onChange={(e) => setSelectedFile1(e.target.files[0])}
                 htmlFor="side_photo">
                   <input name="side_photo" type="file" id="side_photo" hidden />
                   <TbPhotoEdit size="26px" />
                   </label>
                   </div> 
                </div>
              </div>
              {/* <div className='col-1'>
                <div className='change-image'>
                <IconButton size='small' onClick={handleRemove}>
                <ImBin size="22px" color='red'/>
                </IconButton>
                </div>
              </div> */}
              <div className='col-1'>
                <div className='change-image'>
                <Link to={`/side-image/${id}`} className='' >
                <BsEyeFill size="26px" color='green'/>
                </Link>
                </div>
                </div>
             
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
          onClick={handleSubmission}
            color="primary"
           variant="contained"
          >
                {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> حفظ البيانات</>
        }
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
