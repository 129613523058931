import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddPromotion = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const PROMOTION_URL = `${BASE_URL}/promotions`; 
  const [loader, setLoader] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const [data, setData] = useState({
      link : "",
      ordering : null,
      is_active : 1,
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("image", selectedFile);
        formData.append("link", data.link);
        formData.append("is_active", data.is_active); 
        formData.append("ordering", data.ordering);
        await fetch (PROMOTION_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
                setLoader(false);
                localStorage.setItem('toastMessage', 'تم إضافة الإعلان');
                window.location.replace('/promotions');
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="إضافة إعلان جديد"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="رابط الإعلان"
                label="رابط الإعلان"
                margin="dense"
                name="link"
                id="link"
                onChange={(e)=> handle(e)} 
                value={data.link}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                helperText="ترتيب الإعلان"
                label="الترتيب"
                margin="dense"
                name="ordering"
                id="ordering"
                onChange={(e)=> handle(e)} 
                value={data.ordering}
                variant="outlined"
              />
            </Grid>
           

            <Grid
              item
              md={6}
              xs={12}
            >
               <label for="inputImage">أضف صورة الإعلان </label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
                required
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-warning text-white px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إضافة الإعلان</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddPromotion.propTypes = {
  className: PropTypes.string
};

export default AddPromotion;
