import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { BiArchiveOut } from "react-icons/bi";
import { RiDeleteBack2Fill } from "react-icons/ri";

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  CardContent, 
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { ShopContext } from 'context/shop-context';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function ArchivedUserTable(props) {
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;
  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/archivedUsers`;

  const { className, ...rest } = props;
  const {deleteDriver, userUnarchive, city, userSearch, convertUtcToLocal} = useContext(ShopContext);
  const classes = useStyles();
  const [item, setItem] = useState([]);

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // const [msg , setMsg] = useState(false);
  // const [isLoading , setIsLoading] = useState(true);
  // const [leng , setLeng] = useState(0);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);

  
  const [selectedCategory, setSelectedCategory] = useState();


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetchSearchData(currentPage);
  }, [userSearch]);
  

  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);


  const fetchData =  async (pageNumber) => {
    await axios.get(`${PRODUCT_URL}?perPage=10&page=${pageNumber}`,  { 
      headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSearchData =  async (pageNumber) => {
    if (userSearch =='' || userSearch==null) {
      fetchData();
    }
    await axios.get(`${PRODUCT_URL}/search/${userSearch}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${PRODUCT_URL}/city/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then(  (response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    // setLeng(response.data.data.length);
    // setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};
  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };
  
const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
}


  useEffect(()=>{
    setItem(data);
   
  },[data])


function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
}

function getFilteredList() {
  if (!selectedCategory) {
    // setMsg(false);
    return data; 
  }

  setFilterCurrentPage(0);

  return filteredData;
}
var filteredList = useMemo(getFilteredList, [data, selectedCategory, filteredData, item]);
// useEffect(()=>{
// if(leng == 0 ){
//   setMsg(true);
// }
// else{
//   setMsg(false);
// }
// }, [leng])

// console.log(msg, item);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>

<div className='category-filter m-2 '>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="ar_name"
                name="ar_name"
                onChange={handleCategoryChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع المدن</option>
                {city.slice(0).map(option => (
                  <option
                    key={option.id}
                    value={option.ar_name}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            </div>

        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
             
                  <TableCell className='d-flex align-items-start'>اسم العميل</TableCell>
                  <TableCell align='right'>المدينة</TableCell>
                  <TableCell align='right'>رقم الهاتف</TableCell>
                  <TableCell align='right'>نوع الحساب</TableCell>
                  <TableCell align='right'>تاريخ الانضمام</TableCell>
                  <TableCell align='right'>إلغاء الأرشفة</TableCell>
                  <TableCell align='right'>حذف الحساب</TableCell>
                </TableRow>
              </TableHead>
  
              <TableBody>
              {!filteredList ? <></> : 
              <>
                {filteredList.slice(0).map(post => (
               
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >     
       
                    <TableCell>
                   
                      <div className={classes.nameContainer}>
                      {!(localhost_url + post.photo) && localhost_url + post.photo !="null" ?
                        <Avatar
                       
                          className={classes.avatar}
                          src={localhost_url + post.photo}
                        >
                        </Avatar>
                        :
                        <Avatar
                       className={classes.avatar}
                     >
                       {getInitials(post.name)}
                     </Avatar>
                     }
                        <Typography variant="body1" className='ar-name mx-2'>{post.name}</Typography>
                      </div>
                    </TableCell> 
                    <TableCell align='right'>{post.city_ar_name}</TableCell>
            
                    <TableCell align='right' dir='ltr'>{post.phone}</TableCell>
                    { post.type == 'App\\Models\\Company' ?  
                     <TableCell align='right' dir='ltr'>شركة</TableCell>
                    :  post.type == 'App\\Models\\Agent' ?  
                    <TableCell align='right' dir='ltr'>وكيل</TableCell>
                    : post.type == 'App\\Models\\Driver'?  
                    <TableCell align='right' dir='ltr'>سائق</TableCell>
                    :
                    <TableCell align='right' dir='ltr'>عميل</TableCell>
                    }
                    <TableCell align='right' dir='ltr'>{moment(convertUtcToLocal(post.created_at).toString()).format('DD-MM-YYYY hh:mm A')}</TableCell>
                    <TableCell align='right'>
                    <button className='btn btn-success btn-sm' onClick={(e)=> userUnarchive(e, post.id)} ><BiArchiveOut size="20px" /></button>
                    </TableCell>
                    <TableCell align='right' size='small'>
                      <button className='btn btn-danger btn-sm' onClick={(e) => deleteDriver(e, post.id)}><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>

                  </TableRow>
                ))}
                </>
                  }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div className='pagination' dir='ltr' >
          <ReactPaginate  
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ? filteredPageCount : pageCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={selectedCategory ? hnadleFilteredPageClick : handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>

  );
}

export default ArchivedUserTable;
