import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddPolicy = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const POLICY_URL = `${BASE_URL}/policies`;
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
console.log(passedToken);
  const [arText, setArText] = useState("");
  const [enText, setEnText] = useState("");

  const [loader, setLoader] = useState(false);
 
  const handleSubmission =  (e) => {
    e.preventDefault();
      setLoader(true);
      const formData = new FormData();
      formData.append("ar_text", arText);
      formData.append("en_text", enText);
       fetch(POLICY_URL, {
        method: "POST",
        body: formData
      ,
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }})
      .then((res)=>{
          if(!res.status){
            toast.error('فشل الاتصال بالخادم')
            setLoader(false);
          
          }
          else if(res.status == 201){
            setLoader(false);
             localStorage.setItem('toastMessage', 'تم إضافة سياسة الخصوصية ');
             window.location.replace('/policies');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else if(res.status == 500){
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
      }).catch(()=>{
        toast.error('حدث خطأ في الإدخال حاول مرة أخرى ');
        setLoader(false);
       
      });
    };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
          title="إضافة ساسية الخصوصية"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
              
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="سياسة الخصوصة باللغة العربية"
                margin="dense"
                id="arText"
                type="text" 
                name="arText"
                onChange={(e) => setArText(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="سياسة الخصوصة باللغة الإنجليزية"
                margin="dense"
                id="enText"
                type="text"
                name="enText"
                onChange={(e) => setEnText(e.target.value)} 
                required
                variant="outlined" />
            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          
          <button className='btn btn-warning px-3 text-white m-4'  >
  
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إضافة سياسة الخصوصية</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddPolicy.propTypes = {
  className: PropTypes.string
};

export default AddPolicy;
