import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const DriversToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const {sendMessageToDrivers, sendNotificationToDrivers, setDriverSearch} = useContext(ShopContext);
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
          <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(e)=> sendMessageToDrivers(e)}
          >
        إرسال رسالة جماعية
        </Button>
        </div>
        <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(e)=> sendNotificationToDrivers(e)}
          >
        إرسال إشعار جماعي
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="بحث عن سائق"
          onChange={(e)=> setDriverSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

DriversToolbar.propTypes = {
  className: PropTypes.string
};

export default DriversToolbar;
