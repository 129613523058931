import React from 'react';
import { Page, View, Text, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import font1 from '../../../../assets/fonts/JannaLT-Regular.ttf';
import img1 from '../../../../assets/img/dam-2.png';
import moment from 'moment';



Font.register({
  family: 'din-next-arabic-bold',
  src: font1
});


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    margin: 10,
    fontSize: 18,
    fontFamily: 'din-next-arabic-bold',
    backgroundColor: '#d9d9d9',
    textAlign: 'center',
  },
  
  image: {
    marginVertical: 10,
    marginHorizontal: 10,
    width:60,
    height:60,
    textAlign: 'justify',
    marginButton: 10,
  },
  text: {
    marginHorizontal: 10,
    fontSize: 14,
    fontFamily: 'din-next-arabic-bold',
    textAlign: 'right',
    border: 1,
  },
  price:{
    margin: 50,
    fontSize: 18,
    fontFamily: 'din-next-arabic-bold',
    backgroundColor: '#d9d9d9',
    textAlign: 'center',
  },
  qrCode: {
    margin: 5,
  },
  
});

const Map  = ({items}) =>{

  return(
    <Document>
    <Page size="A4" style={styles.page}>
      
 
    </Page>
    <View style={styles.section}>
          <Text style={styles.title}>فاتورة الطلب</Text>
          <Image
        style={styles.image}
        src={img1}
      />
          <Text style={styles.text}> {moment(items.created_at).format('DD-MM-YYYY hh:mm A')}  التاريخ :</Text>
          <Text style={styles.text}> {items.id} رقم الفاتورة :</Text>
          <Text style={styles.text}> {items.customer_name}  المستفيد :</Text>
          <Text style={styles.text}> {items.customer_phone}  رقم الهاتف :</Text>
          <Text style={styles.text}> {items.request_city}  مدينة الطلب :</Text>
          <Text style={styles.text}> {items.service_name}  نوع الخدمة : </Text>
          {items.status == 'canceled' ?  
          <Text style={styles.text}>  حالة الطلب : طلب ملغي</Text>
          : items.status == 'completed' ?
          <Text style={styles.text}>  حالة الطلب : طلب مكتمل</Text>
          :
          <Text style={styles.text}>  حالة الطلب : الطلب قيد الإجراء </Text>
          }
          
           <Text style={styles.price}>  ر. س. {items.price}  القيمة الإجمالية : </Text>

      </View>
  </Document>
    );
}

export default Map;
