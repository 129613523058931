import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddVehicleModel = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const CITY_URL = `${BASE_URL}/vehiclesModels`;
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
  console.log(passedToken);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [loader, setLoader] = useState(false);
 
  const handleSubmission =  (e) => {
    e.preventDefault();
      setLoader(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
       fetch(CITY_URL,{
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        },
      
        method: "POST",
        body: formData
      })
      .then((res)=>{
          if(!res.status){
            toast.error('فشل الاتصال بالخادم')
            setLoader(false);
          
          }
          else if(res.status == 200){
            setLoader(false);
             localStorage.setItem('toastMessage', 'تم إضافة الموديل ');
             window.location.replace('/models');
          }
          else if(res.status == 401){
            toast.error('وصول غير مصرح به');
            setLoader(false);
          }
          else if(res.status == 500){
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
          else if(res.status == 404){
            toast.error('الرجاء التاكد من الاتصال بالانترنت والمحاولة مرة أخرى')
            setLoader(false);
          }
          else{
            toast.error('حدث خطأ في الإدخال حاول مرة أخرى ')
            setLoader(false);
          }
      })
      .catch(()=>{
        toast.error('حدث خطأ في الإدخال حاول مرة أخرى ');
        setLoader(false);
       
      });
    };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
          title="أضافة موديل"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
              
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                fullWidth
                helperText="اسم الموديل باللغة العربية"
                label= "اسم الموديل باللغة العربية"
                margin="dense"
                id="name"
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)} 
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="اسم الموديل باللغة الانجليزية"
                label= "اسم الموديل باللغة الانجليزية"
                margin="dense"
                id="description"
                type="text" 
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
       

<button className='btn btn-warning px-3 text-white m-4'  >

  
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>اضافة الموديل</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddVehicleModel.propTypes = {
  className: PropTypes.string
};

export default AddVehicleModel;
