import React, { useContext, useEffect} from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { RiDeleteBack2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


function RolesTable (props) {
  const { className, ...rest } = props;
  const {roles, deleteRole} = useContext(ShopContext);

  const classes = useStyles();
  const toastMessage = localStorage.getItem('toastMessage');

  useEffect(() => {
    toast.success(toastMessage)
    localStorage.removeItem('toastMessage');
  }, []);
  let counter = 1;


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <Divider />
     
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>التسلسل</TableCell>
                  <TableCell align='right'>دور المستخدم</TableCell>
                  {/* <TableCell align='right'>دور المستخدم</TableCell> */}
                  <TableCell align='right'>تعديل</TableCell>
                  <TableCell align='right'>حذف</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map(order => (
                  <TableRow
                    hover
                    key={order.id}
                  >
                
                    <TableCell align='right'>{ counter++} </TableCell>
                    <TableCell align='right'>{order.role}</TableCell>
                    {/* <td>
                                    {order.permissions.map((permit)=>  (<label key={permit.id}> 
                                    {permit.permission} , </label> )     
                                    )}</td> */}
                    <TableCell align='right'>
                    <Link to={`/edit-role/${order.id}`} className='btn btn-primary btn-sm' ><FaEdit size="20px" /></Link>
                    </TableCell>
              
                    <TableCell align='right'>
                    <button className='btn btn-danger btn-sm' onClick={(e)=> deleteRole(e, order.id)}><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>
                  </TableRow>
                 ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
    
      </CardActions>
    </Card>
  );
}

export default RolesTable;
