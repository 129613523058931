import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddDiscount = props => {

  const { className, ...rest } = props;
  const classes = useStyles();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const DISCOUNT_URL = `${BASE_URL}/coupons`; 
  const [loader, setLoader] = useState(false);

  // const history = useHistory();

  const [data, setData] = useState({
      discount : 0,
      created_dateTime : "",
      expiration_dateTime : "",
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("discount", data.discount);
        formData.append("created_dateTime", data.created_dateTime);
        formData.append("expiration_dateTime", data.expiration_dateTime);
        await fetch (DISCOUNT_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
              // toast.success('تم إضافة كود الخصم');
                localStorage.setItem('toastMessage', 'تم إضافة كود الخصم');
                window.location.replace('/discount');
                setLoader(false);
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="إضافة كود خصم جديد"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="تاريخ البداية"
                margin="dense"
                type='date'
                name="created_dateTime"
                id="created_dateTime"
                onChange={(e)=> handle(e)} 
                value={data.created_dateTime}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="تاريخ النهاية"
                margin="dense"
                type='date'
                name="expiration_dateTime"
                id="expiration_dateTime"
                onChange={(e)=> handle(e)} 
                value={data.expiration_dateTime}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                label="قيمة الخصم "
                margin="dense"
                name="discount"
                id="discount"
                onChange={(e)=> handle(e)} 
                value={data.discount}
                required
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-warning text-white px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>إضافة كود الخصم</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddDiscount.propTypes = {
  className: PropTypes.string
};

export default AddDiscount;
