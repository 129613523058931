import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField
} from '@material-ui/core';

import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function ServiceTable(props) {

    const toastMessage = localStorage.getItem('toastMessage');
  
    useEffect(() => {
      toast.success(toastMessage)
      localStorage.removeItem('toastMessage');
    }, []);
  
  const BASE_URL = process.env.REACT_APP_API_URL;
  const SERVICES_URL = `${BASE_URL}/getServices`;
  const SERVICES_URL1 = `${BASE_URL}/services`;

  const { className, ...rest } = props;
  const {deleteService, subCategoriesList, serviceSearch} = useContext(ShopContext);
  const classes = useStyles();
  const [item, setItem] = useState([]);

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading , setIsLoading] = useState(false);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);

  
  const [selectedCategory, setSelectedCategory] = useState();


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetchSearchData(currentPage);
  },[serviceSearch]);

  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);
  
 
  const fetchData =  async (pageNumber) => {
    await axios.get(`${SERVICES_URL}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const fetchSearchData =  async (pageNumber) => {
    if (serviceSearch =='' || serviceSearch==null) {
      fetchData();
    }
    await axios.get(`${SERVICES_URL1}/search/${serviceSearch}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${SERVICES_URL}/subCategory/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then(  (response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};

  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };
  
const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
}



  useEffect(()=>{
    setItem(data);
   
  },[data])


function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
}

function getFilteredList() {
  if (!selectedCategory) {
    return data;
   
  
  }

  setFilterCurrentPage(0);

  return filteredData;
}
var filteredList = useMemo(getFilteredList, [selectedCategory, data, item, filteredData]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>

<div className='category-filter m-2 '>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="ar_name"
                name="ar_name"
                onChange={handleCategoryChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع الأقسام الفرعية</option>
                {subCategoriesList.slice(0).map(option => (
                  <option
                    key={option.id}
                    value={option.ar_name}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            </div>

        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
                <TableCell align='right'>الرقم</TableCell>
                  <TableCell align='right'>اسم الخدمة</TableCell>
                  <TableCell align='right'> القسم الفرعي</TableCell>
                  <TableCell align='right'>سعر الخدمة</TableCell>
                  <TableCell align='right'>سعر الكيلو متر</TableCell>
                  <TableCell align='right'> العمولة</TableCell>
                  <TableCell align='right'> حالة الخدمة</TableCell>
                  <TableCell align='right'>كود الخصم</TableCell>
                  <TableCell align='right'>تعديل</TableCell>
                  <TableCell align='right'>حذف</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <h6 align='left' className='text-secondary content-align-end m-3'>جاري تحميل المنتجات ...</h6>:
              <>
             
              <TableBody>
                {filteredList.slice(0).map(post => (
               
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >     
                    <TableCell align='right'>{post.id}</TableCell>
                    <TableCell align='right'>{post.ar_name}</TableCell>
                    <TableCell align='right'>{post.sub_cat_ar_name  }</TableCell>
                    <TableCell align='right'>{post.price}</TableCell>
                    <TableCell align='right'>{post.unit_price_per_killo}</TableCell>
                    <TableCell align='right'>{post.commission} <span> % </span> </TableCell>
                    
                    {post.is_active == 1 ? 
                      <TableCell align='right'>مفعلة</TableCell>
                      :
                      <TableCell align='right'>غير مفعلة</TableCell>
                      }
                  
                    {post.coupon_code  == null || post.coupon_code  == 'null' ?  <TableCell align='right'></TableCell>:  <TableCell align='right'>{post.coupon_code}</TableCell>}
                    <TableCell align='right'>
                    <Link to={`/edit-service/${post.id}`} className='btn btn-primary btn-sm' ><FaEdit size="20px" /></Link>
                    </TableCell>
              
                    <TableCell align='right'>
                    <button className='btn btn-danger btn-sm' onClick={(e)=> deleteService(e, post.id)} ><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              </>
 }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions} >
        <div className='pagination' dir='ltr'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ? filteredPageCount : pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={selectedCategory ? hnadleFilteredPageClick : handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>

  );
}

export default ServiceTable;
