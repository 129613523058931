import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';

import { RiDeleteBack2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function PromotionsTable(props) {

  const toastMessage = localStorage.getItem('toastMessage');
  useEffect(() => {
    toast.success(toastMessage)
    localStorage.removeItem('toastMessage');
  }, []);
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;


  const { className, ...rest } = props;
  const {deletePromotion, promotions} = useContext(ShopContext);
  const classes = useStyles();


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>

              <TableHead >
                <TableRow >
             
                  <TableCell align='right'> التسلسل</TableCell>
                  <TableCell  align='center'>الإعلان</TableCell>
                  <TableCell  align='right'>الترتيب</TableCell>
                  <TableCell align='right'>حالة الإعلان</TableCell>
                  <TableCell  align='right'>تعديل</TableCell>
                  <TableCell align='right'>حذف</TableCell>
              
                </TableRow>
              </TableHead>
            
              <TableBody>
                {promotions.slice(0).map(post => (
               
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >

                    <TableCell align='right'>{post.id}</TableCell>
                    <TableCell align='center'><img src={localhost_url + post.image} width="100px" className='text-center'/></TableCell>
            
                    <TableCell align='right'>{post.ordering}</TableCell>
                    {post.is_active == 1 ? 
                     <TableCell align='right'>مفعل</TableCell>
                    :
                    <TableCell align='right'>غير مفعل</TableCell>
                    }
                    <TableCell align='right'>
                    <Link to={`/edit-promotion/${post.id}`} className='btn btn-primary btn-sm' ><FaEdit size="20px" /></Link>
                    </TableCell>
              
                    <TableCell align='right'>
                    <button className='btn btn-danger btn-sm' onClick={(e)=> deletePromotion(e, post.id)} ><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>

  );
}

export default PromotionsTable;
