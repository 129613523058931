import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 

import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditDiscount = props => {

  const { className, ...rest } = props;
  const classes = useStyles();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const {id} = useParams();
  const DISCOUNT_URL = `${BASE_URL}/coupons/${id}`; 
  const [loader, setLoader] = useState(false);


  
  const [data, setData] = useState({
    discount : 0,
    created_dateTime : "",
    expiration_dateTime : "",
    
});

  // const history = useHistory();
  useEffect(() => {
    loadList();
  }, []);
  const loadList = async () => {
    const res = await axios.get(DISCOUNT_URL,{ headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  });
    setData(res.data)
  };

  console.log('id', data);

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("discount", data.discount);
        formData.append("created_dateTime", data.created_dateTime);
        formData.append("expiration_dateTime", data.expiration_dateTime);
        formData.append("is_active", data.is_active);
        
        await fetch (DISCOUNT_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
              // toast.success('تم تعديل كود الخصم');
              // setLoader(false);
              //   history.push('/discount');

                setLoader(false);
                localStorage.setItem('toastMessage', 'تم تعديل كود الخصم ');
                window.location.replace('/discount');
            }
            else if(res.status == 401){
              toast.error('وصول غير مصرح به');
              setLoader(false);
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
            }
            
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="تعديل كود الخصم"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="تاريخ البداية"
                margin="dense"
                type='date'
                name="created_dateTime"
                id="created_dateTime"
                onChange={(e)=> handle(e)} 
                value={data.created_dateTime}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="تاريخ النهاية"
                margin="dense"
                type='date'
                name="expiration_dateTime"
                id="expiration_dateTime"
                onChange={(e)=> handle(e)} 
                value={data.expiration_dateTime}
                variant="outlined"
                
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                type='number'
                label="قيمة الخصم "
                margin="dense"
                name="discount"
                id="discount"
                onChange={(e)=> handle(e)} 
                value={data.discount}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="حالة الكود"
                // helperText="أختر الصنف بالعربي"
                margin="dense"
                id="is_active"
                name="is_active"
                onChange={(e)=> handle(e)} value={data.is_active}
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
             <option value={1}>مفعل </option>
             <option value={0}>غير مفعل </option>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-warning text-white px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>تعديل البيانات</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditDiscount.propTypes = {
  className: PropTypes.string
};

export default EditDiscount;
