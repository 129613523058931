import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function FrontImage() {
  const {id} = useParams();

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const BASE_URL = process.env.REACT_APP_API_URL;
  const VEHICLES_URL = `${BASE_URL}/vehicles/${id}`;
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState("");

  useEffect(()=>{
    axios.get(VEHICLES_URL, {
      headers:{
     'Authorization': ` Bearer ${passedToken}`
    }
      }).then((res)=>{
      setVehicle(res.data);
      console.log(res.data);
      setIsLoading(false);
    }).catch((err)=>{
      console.log(err);
    })
  }, [VEHICLES_URL])
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;

  return (
    <div>
     {isLoading ? <></> :
      <div className='container text-center my-5'>
  <img src={localhost_url + vehicle.front_photo} width="600px" height="800px"/>
  </div>
  }
 
    
    </div>
  )
}

export default FrontImage
