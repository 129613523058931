import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { BsBuildingFill } from 'react-icons/bs';
import { BsFillPhoneFill } from 'react-icons/bs';
import { FaRegistered } from 'react-icons/fa';
import { FaCreditCard } from 'react-icons/fa';


import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useEffect } from 'react';


import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Button,
  TextField,
  InputAdornment,

} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

    const {id} = useParams(); 

    const BASE_URL = process.env.REACT_APP_API_URL;
    const AGENTS_URL = `${BASE_URL}/agents/${id}`; 
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    console.log(history);

    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
      const res = await axios.get(AGENTS_URL,{ headers:{
        'Authorization': ` Bearer ${passedToken}`
      }
    });
      setData(res.data);
      console.log(res.data);
    };
   

    const [data, setData] = useState({
      name : "",
      phone : "",
      bank_account : 0,
      registration_no: 0,
     
  
    });


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
  console.log(passedToken);

    const handleSubmission = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("bank_account", data.bank_account);
      formData.append("registration_no", data.registration_no);
      await fetch(AGENTS_URL, {
        method: "POST",
        body: formData,
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      
      })
      .then((res)=>{
        setLoader(false);
        console.log(res);
        if(!res.status){
          toast.error('فشل الاتصال بالخادم الرجاء المحاولة مرة أخرى');
        }

        else if(res.status == 500){
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        }
        else if(res.status == 404){
          setLoader(false);
          toast.error('تأكد من الاتصال و المحاولة مرة أخرى');
        }
        else if(res.status == 200){
          setLoader(false);
             toast.success('تم تعديل البيانات');
        }
        else{
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        } 
        loadList();
      })
      .catch(()=>{
        toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        setLoader(false);
      });
    };

    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
      console.log(newdata)
    }

    const localhost_url = process.env.REACT_APP_IMAGE_URL;
    const defaultImage = "https://i.pinimg.com/originals/16/5c/90/165c9078c5099ba03ec3bec0e4518a14.jpg";


  return (
    <Card 
      {...rest}
      className={clsx(classes.root, className)}
    >
       <form onSubmit={handleSubmission}>
        <CardHeader
          title="بيانات الشركة"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={12}
              sm={6}
              xs={12}
            > 
            <div className='d-flex justify-content-center'>
            <img class="avatar avatar-48 bg-light rounded-circle text-white p-2 justify-content-center "
               src={(!localhost_url + data.photo) ?  (localhost_url + data.photo) : defaultImage} width="360px" height="360" />
              </div>
              <Grid
              item
              md={12}
              xs={12}
            >
              <TextField InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <BsBuildingFill    size="22px"/>
                </InputAdornment>
            )}}
                fullWidth
                helperText=""
                margin="dense"
                name="name"
                id="name"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.name}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <BsFillPhoneFill size="24px"/>
                </InputAdornment>
            )}}
                fullWidth
                helperText=""
                margin="dense"
                name="phone"
                id="phone"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.phone}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField 
              InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <FaCreditCard size="22px"/>
                </InputAdornment>
            )}}
                fullWidth
                helperText=""
                margin="dense"
                name="bank_account"
                id="bank_account"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.bank_account}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField 
              InputProps={{startAdornment: (
                <InputAdornment position="start">
                  <FaRegistered size="24px"/>
                </InputAdornment>
            )}}
                fullWidth
                helperText=""
                margin="dense"
                name="registration_no"
                id="registration_no"
                required
                variant="outlined"
                onChange={(e)=> handle(e)} 
                value={data.registration_no}
              />
            </Grid>
          
          
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
          onClick={handleSubmission}
            color="primary"
           variant="contained"
          >
                {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> حفظ البيانات</>
        }
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
