import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { BsEyeFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HiUserCircle } from 'react-icons/hi';
import { MdPhoneIphone } from 'react-icons/md';
import { AiFillCreditCard } from 'react-icons/ai';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Grid
   
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(0)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function DriversTable(props) {
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;
  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/drivers`;

  
  const { className, ...rest } = props;
  const {userArchive, city, getDriver, driver, driverSearch} = useContext(ShopContext);
  const classes = useStyles();
  const [item, setItem] = useState([]);


  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [msg , setMsg] = useState(false);
  console.log(msg);
  const [isLoading , setIsLoading] = useState(false); //change it to true
  const [leng , setLeng] = useState(0);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState();

  const [DriverCurrentPage, setDriverCurrentPage] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState();
  const [DriverPageCount, setDriverPageCount] = useState(0);
  const [filteredDriverData, setfilteredDriverData] = useState([]);

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetchSearchData(currentPage);
  },[driverSearch]);

  useEffect(() => {
    fetchFilteredDriverData(DriverCurrentPage);
  },[selectedDriver]);


  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);
 
  const fetchData =  async (pageNumber) => {
    await axios.get(`${PRODUCT_URL}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchSearchData =  async (pageNumber) => {
    if (driverSearch =='' || driverSearch==null) {
      fetchData();
    }
    await axios.get(`${PRODUCT_URL}/search/${driverSearch}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchFilteredDriverData =   async (pageNumber) => {
    const post = { is_active: selectedDriver }
    await axios.post(`${PRODUCT_URL}/filter/getFilterData?perPage=10&page=${pageNumber}`, post, { 
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }}).then((response)=>{
        setfilteredDriverData(response.data.data);
        setDriverPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    }

const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${PRODUCT_URL}/city/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then((response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    setLeng(response.data.data.length);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};

  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };

  const hnadleFilteredOrderPageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setDriverCurrentPage(selectedPage);
    fetchFilteredDriverData(selectedPage);
  };

  
  
const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
}

  useEffect(()=>{
    setItem(data);
   
  },[data])


function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
  setSelectedDriver(false);
}

function handleDriverChange(event) {
  setSelectedDriver(event.target.value);
  setSelectedCategory(false);
}

function getFilteredList() {
  if (selectedCategory) {
    setFilterCurrentPage(0);

    return filteredData;
  }
  if(selectedDriver){
    setDriverCurrentPage(0);
    return filteredDriverData;
  }

  setMsg(false);
  return data;
}

var filteredList = useMemo(getFilteredList, [selectedCategory, data, filteredData, item, filteredDriverData]);

useEffect(()=>{
if(leng == 0 ){
  setMsg(true);
}
else{
  setMsg(false);
}
}, [leng])
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const defaultImage = "https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png";

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
            
                  <Modal show={show} onHide={handleClose} centered='true'>
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <div className='d-flex justify-content-center'>
                          {/* <img class="avatar avatar-48 bg-light rounded-circle text-white p-2 justify-content-center "
                            src="https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png" width="50%" /> */}
                             <img class="avatar avatar-48 bg-light rounded-circle text-white p-2 justify-content-center"
                               src={(!localhost_url + driver.photo) ?  (localhost_url + driver.photo) : defaultImage}  width="280px" height="280" />
                        </div>
                        <TextField InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HiUserCircle size="30px" />
                            </InputAdornment>
                          )
                        }}
                          fullWidth
                          label="اسم السائق"
                          margin="dense"
                          name="name"
                          id="name"
                          variant="outlined"
                          value={driver.name}
                        >
                        </TextField>

                        <TextField InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MdPhoneIphone size="30px" />
                            </InputAdornment>
                          )
                        }}
                          fullWidth
                          label="رقم الهاتف"
                          margin="dense"
                          name="phone"
                          id="phone"
                          variant="outlined"
                          value={driver.phone}
                        >
                        </TextField>

                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AiFillCreditCard size="24px" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          label="رقم الحساب البنكي"
                          margin="dense"
                          name="bank_account"
                          id="bank_account"
                          variant="outlined"
                          value={driver.bank_account}
                        >
                        </TextField>

                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AiFillCreditCard size="24px" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          label="العنوان"
                          margin="dense"
                          name="city_ar_name"
                          id="city_ar_name"
                          variant="outlined"
                          value={driver.city_ar_name}
                        >
                        </TextField>

                        <TextField InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">

                              <AiFillCreditCard size="26px" />
                            </InputAdornment>
                          )
                        }}
                          fullWidth
                          label="حالة الحساب"
                          margin="dense"
                          id="is_active"
                          name="is_active"
                          select
                          variant="outlined"
                          SelectProps={{ IconComponent: () => null, native: true }}>

                          <option
                            value={driver.is_active}
                          >
                            {driver.is_active == 1 ? 'مفعل' : driver.is_active == 2 ? 'في انتظار الموافقة على التعديلات' 
                            : driver.is_active == 0 ? ' طلب انضمام جديد' : 'غير مفعل'}
                          </option>


                        </TextField>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          الغاء
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Grid
            container
            spacing={3}
          >
              
            <Grid
              item
              md={3} 
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="en_name"
                name="en_name"
                onChange={handleCategoryChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع المدن</option>
                {city.slice(0).map(option => (
                  <option
                    key={option.id}
                    value={option.ar_name}
                  >
                    {option.ar_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="status"
                name="status"
                onChange={handleDriverChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع السائقين</option>
                <option  value={1}>مفعل</option>
                <option  value={0}>طلب انضمام جديد</option>
                <option  value={2}>في انتظار الموافقة على التعديلات </option>
                <option  value={3}>غير مفعل</option>
              </TextField>
            </Grid>
            </Grid>
              
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
                 
                  <TableCell align='right' size='small'>اسم السائق</TableCell>
                  <TableCell align='right' size='small'>رقم السائق</TableCell>
                  <TableCell align='right' size='small'>المدينة</TableCell>
                  <TableCell align='right' size='small'>رقم الجوال</TableCell>
                  <TableCell align='right' size='small'>نوع  الحساب</TableCell>
                  <TableCell align='right' size='small'>نوع الالية</TableCell>
                  <TableCell align='right' size='small'>التقييم</TableCell>
                  <TableCell align='right' size='small'>حالة الحساب</TableCell>
                  <TableCell align='right' size='small'>عرض البيانات</TableCell>
                  <TableCell align='right' size='small'>تعديل</TableCell>
                  <TableCell align='right' size='small'>أرشفة الحساب</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <h6 align='left' className='text-secondary content-align-end m-3'>جاري تحميل بيانات السائقين ...</h6>:
              <>
              <TableBody>
                {filteredList.slice(0).map(post => (
                  <><TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        {(!localhost_url + post.photo) && (localhost_url + post.photo != "null") ?
                          <Avatar
                            className={classes.avatar}
                            src={localhost_url + post.photo}
                          >
                          </Avatar>
                          :
                          <Avatar
                            className={classes.avatar}
                          >
                            {getInitials(post.name)}
                          </Avatar>}
                        <Typography variant="body1" className='ar-name mx-2'>{post.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell align='right' size='small'>{post.id}</TableCell>
                    <TableCell align='right' size='small'>{post.city_ar_name}</TableCell>
                    <TableCell align='right' dir='ltr' size='small'>{post.phone}</TableCell>
                    {post.is_agent  == 1 ?
                      <TableCell align='right' size='small'>سائق وكيل</TableCell>
                      :
                      <TableCell align='right' size='small'>سائق مستقل</TableCell>}
                    <TableCell align='right' size='small'>{post.service_name}</TableCell>
                    <TableCell align='right' size='small'>{post.rate}</TableCell>
                    {post.is_active == 1 ?
                      <TableCell align='right' size='small'> مفعل</TableCell> :
                      post.is_active == 2 ?
                        <TableCell align='right' size='small' style={{ backgroundColor: '#f1c40f', color: 'white', }}> في انتظار الموافقة على التعديلات</TableCell>
                        :
                        post.is_active == 3 ?
                          <TableCell align='right' size='small'>غير مفعل</TableCell>
                          :
                          <TableCell align='right' size='small' style={{ backgroundColor: 'green', color: 'white', }}>طلب انضمام جديد </TableCell>}
                    <TableCell align='right' size='small'>
                      <button onClick={ (e)=> [handleShow(), getDriver(e, post.id)] } className='btn btn-secondary btn-sm'><BsEyeFill size="20px" /></button>
                    </TableCell>
                    <TableCell align='right' size='small'>
                      <Link to={`/driver-settings/${post.id}`} className='btn btn-primary btn-sm'><FaEdit size="20px" /></Link>
                    </TableCell>
                    <TableCell align='right' size='small'>
                      <button className='btn btn-danger btn-sm' onClick={(e) => userArchive(e, post.id)}><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>
                  </TableRow>
                  
                  </>
                ))}
              </TableBody>
              </>
 }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div className='pagination' dir='ltr'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ? filteredPageCount ? selectedDriver : DriverPageCount : pageCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={selectedCategory ? hnadleFilteredPageClick ? selectedDriver : hnadleFilteredOrderPageClick : handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>
  );
}
export default DriversTable;
