import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { Notifications } from 'react-push-notification';
import {Helmet} from "react-helmet";
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { ShopContextProvider } from 'context/shop-context';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';



const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};


var counterContainer = document.querySelector(".website-counter");
var visitCount = localStorage.getItem("page_view");

// Check if page_view entry is present
if (visitCount) {
  visitCount = Number(visitCount) + 1;
  localStorage.setItem("page_view", visitCount);
} else {
  visitCount = 1;
  localStorage.setItem("page_view", 1);
}
console.log(counterContainer);



export default class App extends Component {
  
  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      alert('Right-click is disabled on this page.');
    });
  }

  render() {

   
    
    return (
      <ThemeProvider theme={theme}>
          <Helmet>
          <title>DAM Dashboard - لوحة التحكم</title>
        <meta name="description" content="DAM Dashboard - لوحة التحكم" />
    </Helmet>
          <ShopContextProvider >  
          <Notifications />
          {/* <PushNotifications /> */}
          <ToastContainer />
        <Router history={browserHistory}>
          <Routes />
        </Router>
        </ShopContextProvider>
      </ThemeProvider>

    );
  }
}
