import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";




const firebaseConfig = {
  apiKey: "AIzaSyAWaamk19qRoPUHYHJjpabF6yjyE_z-DOE",
  authDomain: "wajen-dam.firebaseapp.com",
  projectId: "wajen-dam",
  storageBucket: "wajen-dam.appspot.com",
  messagingSenderId: "846098322493",
  appId: "1:846098322493:web:75bbe98732fd2fc18a8654",
  measurementId: "G-YRQ9GRXVR4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

console.log(analytics);


ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
